import React, { useState } from 'react';
import { Route } from 'react-router-dom';
import { animated } from 'react-spring';
import { useLocation, useHistory } from 'react-router';

import MainShift from '../02-MainShift';
import StartTrip from './02-StartTrip';
import Pickup from './03-Pickup';
import OrderInfo from './04-OrderInfo';
import ProviderSelection from './05-ProviderSelection';
import Departure from './06-Departure';
import Trips from './07-Trips';
import Delivered from './08-Delivered';
import PayInfo from './09-PayInfo';
import ActionMenu from '../../../ActionMenu';

import {
  tripSort,
  getSavedState,
  setSavedState,
  sequenceAddOn,
  sequenceDoubleUp
} from '../../../helpers/trips/TripHelpers';
import { useRoutingTransition } from '../../../helpers/animationHelpers';
import sortedState from '../../../helpers/trips/UpdateHelpers';
import { createTrip, createStamps } from '../../../helpers/CreationHelpers';

export default function TripRouter(
  { currentUser, 
    setStage, 
    updateShift, 
    shiftStageId, 
    updateShiftStageId,
    tripView,
    style,
    previousId,
    setPreviousId,
    ...shiftState 
  }) {

  const history = useHistory();
  const { pathname } = useLocation();
  const [trips, setTrips] = useState(
    getSavedState('trips') || []
  );

  const [trip, setTrip] = useState(
    getSavedState('trip') || createTrip(trips.length)
  );

  const [stamps, setStamps] = useState(
    getSavedState('tripStamps') || createStamps()
  );

  const [switchTrigger, setSwitchTrigger] = useState(
    getSavedState('switchTrigger') || false
  );
  
  const tripsSort = tripSort(trips);

  const runningDistanceTotal = () => {
    const {shift} = shiftState;
    return shift?.total?.distance || shift?.start?.distance
  };

  const deleteTrip = () => {
    const revisedTrips = trips.slice(0,-1);
    const revisedTrip = revisedTrips[revisedTrips.length - 1] || createTrip(revisedTrips.length);

    setSavedState({
      trips: revisedTrips,
      trip: revisedTrip
    })

    setTrip(revisedTrip);
    setTrips(revisedTrips);
    history.push('/shift/active')
  }

  const updateTrip = (tripData) => {
    const { isMultiTrip, switchTriggerToggle, ...tripProps } = tripData;

    const sortProps = {
      ...tripProps,
      trip,
      trips,
      stamps,
    };

    const standardSequence = () => sortedState(sortProps);

    const addOnSequence = () => {
      return sequenceAddOn({
        sortedState, 
        sortProps, 
        createTrip, 
        tripsSort, 
        stamps,
        createStamps
      });
    }

    const doubleUpSequence = () => {
      return sequenceDoubleUp({
        sortedState, 
        sortProps, 
        createTrip, 
        tripsSort, 
        stamps,
        createStamps
      });
    }

    const determineMultiTrip = () => (isMultiTrip === 'doubleUp' ? doubleUpSequence() : addOnSequence());

    const determineSequence = isMultiTrip ? determineMultiTrip(): standardSequence();

    const { updatedTrip, updatedTrips, sortedStamps } = determineSequence;

    setSavedState({
      trip: updatedTrip,
      trips: updatedTrips,
      tripStamps: sortedStamps,
      switchTrigger: switchTriggerToggle || false,
    });

    setStamps(sortedStamps);
    setTrip(updatedTrip);
    setTrips(updatedTrips);
    setSwitchTrigger(switchTriggerToggle);
    
    updateShift({trip: updatedTrip});
  };

  const tripState = {
    trip,
    updateTrip,
    tripsSort,
    setStage,
    runningDistanceTotal,
    style
  };

  const determineTripStyle = pathname.includes('trip') ? style.tripRoute : `${style.tripRoute} ${style.tripFocus}`
  const tripRouteTransition = useRoutingTransition(previousId, setPreviousId, false);

  return (
    <div className = {determineTripStyle}>
      <summary id = {style.tripSummary}>
        <h3>Trip Overview</h3>
          <p>Trip Data</p>
      </summary>

      {tripRouteTransition(( styles, item, key ) => (
        <animated.section
          key = {key}
          style = {styles}
        >
          <Route exact path = "/shift/active">
            <MainShift
              {...shiftState}
              {...tripState}
              trips = {trips}
            />
          </Route>

          <Route path = "/shift/active/trip-start">
            <StartTrip
              {...tripState}
              updateShift = {updateShift}
            />
          </Route>

          <Route path = "/shift/active/trip-pickup">
            <Pickup
              {...tripState}
              updateShiftStageId = {updateShiftStageId}
            />
          </Route>
          
          <Route path = "/shift/active/trip-order-info">
            <OrderInfo
              {...tripState}
              />
          </Route>

          <Route path = "/shift/active/trip-select-provider">
            <ProviderSelection
              {...tripState}
              currentUser = {currentUser}
            />
          </Route>

          <Route path = "/shift/active/trip-departure">
            <Departure
              {...tripState}
              currentUser = {currentUser}
            />
          </Route>

          <Route path = "/shift/active/trip-orders">
            <Trips
              {...tripState}
              setTrip = {setTrip}
            />
          </Route>

          <Route path = "/shift/active/trip-delivery">
            <Delivered
              {...tripState}
              switchTrigger = {switchTrigger}
              updateShiftStageId = {updateShiftStageId}
              shiftStageId = {shiftStageId}
              stamps = {stamps}
            />
          </Route>

          <Route path = "/shift/active/trip-pay">
            <PayInfo 
              {...tripState}
            />
          </Route>
      </animated.section>
      ))}

    {pathname.includes('trip') && <ActionMenu tripActions = {deleteTrip}/>}
    </div>
  );
}