import React from 'react';
import { useHistory } from 'react-router-dom';
import { createStamp } from './../../helpers/trips/TripHelpers';
import useDistanceInput from '../../customHooks/DistanceInput';

export default function StartShift({ shift, setStage, updateShift, style}) {
  const [odometerStart, setOdometerStart] = useDistanceInput(shift?.start?.distance || '');

  const nextPage = '/shift/active';
  const stampDate = Date.now();
  const history = useHistory();

  const startShift = (e) => { 
    e.preventDefault();
    
    const timeStamp = createStamp({
      title: 'time',
      stampValue: stampDate,
      stage: 'startShift',
      placement: 'start'
    })

    const odomStamp = createStamp({
      title: 'distance',
      stampValue: odometerStart,
      stage: 'startShift',
      placement: 'start'
    });

    const stampInputs = [timeStamp, odomStamp];

    const start = {
      time: stampDate,
      distance: odometerStart
    }

    const shiftData = {
      stampInputs,
      start
    }

    updateShift(shiftData);

    setStage(nextPage);
    history.push(nextPage);
  };

  return (
    <div className = {style.stepCard}>
      <h4>Start Shift</h4>

      <form onSubmit = {startShift}>
          <input
            type = "number"
            name = "odometer-start"
            onChange = {e => setOdometerStart(e.target.value)}
            placeholder = 'Input Current Odometer'
            value = {odometerStart}
            step = 'any'
            required
          />

        <button
          type = 'submit'
          disabled = {!odometerStart}
          to = {nextPage}
        >
          Clock In
        </button>
      </form>
    </div>
  );
}