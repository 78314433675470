import React from 'react';
import { Link } from 'react-router-dom';
import { createStamp } from './../../helpers/trips/TripHelpers';

export default function PauseShift({ updateShift, setStage, shiftStageId }) {
  const nextPage = '/shift/active';
  setStage(nextPage);

  console.log(shiftStageId)

  const resumeShift = () => {
    const timeStamp = createStamp({
      title: 'time',
      stampValue: Date.now(),
      stage: `break-${shiftStageId}`,
      placement: 'end'
    })

    const stampInputs = [timeStamp];

    const shiftData = {
      stampInputs
    }

    updateShift(shiftData)
  };

  return (
    <div className = 'shiftRoute'>
      <Link to = {nextPage}>
        <button
          onClick = {resumeShift}
        >
          Resume Shift
        </button>
      </Link>
    </div>
  );
}