import { hourlyPayCalulator, perDistancePayCalulator, round } from "./AppHelpers";

const singleAddTotals = (totals, user) => {
  const userTotals = {
    shiftCount: (user?.lifetime?.total?.shiftCount || 0) + 1
  };


  for (const [stat, value] of Object.entries(totals)) {
    userTotals[stat] = round((user?.lifetime?.total?.[stat] || 0) + value);
  }

  return userTotals;
}

const stageTotals = (stageData, user) => {
  const stageTotals = {};

  for (const [stage, data] of Object.entries(stageData)) {
    const currentUserStage = user?.lifetime?.total?.[stage];

    for (const [stageItem, value] of Object.entries(data)) {

      const currentUserValue = currentUserStage?.[stageItem] || 0;
      const newUserValue = round(currentUserValue + value);

      stageTotals[stage] = {...stageTotals[stage], [stageItem] : newUserValue }
    }
  }

  return stageTotals;
}

const payRateCalculator = (total) => {
  const hourlyPay = round(hourlyPayCalulator(total.time, total.pay));
  const perDistancePay = round(perDistancePayCalulator(total.distance, total.pay));
  
  return {
    hourlyPay,
    perDistancePay
  }
}

const shiftAverageCalculator = (total) => {
  const time = round(total.time / total.shiftCount);
  const distance = round(total.distance / total.shiftCount);
  const pay = round(total.pay / total.shiftCount);
  const providerPay = round(total.providerPay / total.shiftCount);
  const tipPay = round(total.tipPay / total.shiftCount);
  const activeTime = round(total.activeTime / total.shiftCount);
  const activeDistance = round(total.activeDistance / total.shiftCount);

  const pickup = {
    time: round(total.pickup.time / total.shiftCount),
    distance: round(total.pickup.distance / total.shiftCount)
  }

  const wait = {
    time: round(total.wait.time / total.shiftCount),
  }

  const delivery = {
    time: round(total.delivery.time / total.shiftCount),
    distance: round(total.delivery.distance / total.shiftCount),
  }

  return {
    shift: {
      time,
      distance,
      pay,
      providerPay,
      tipPay,
      activeTime,
      activeDistance,
      pickup,
      wait,
      delivery
    }
  }
}

const tripAverageCalculator = (total) => {
  const time = round(total.activeTime / total.tripCount);
  const distance = round(total.activeDistance / total.tripCount);
  const pay = round(total.pay / total.tripCount);
  const providerPay = round(total.providerPay / total.tripCount);
  const tipPay = round(total.tipPay / total.tripCount);

  const pickup = {
    time: round(total.pickup.time / total.tripCount),
    distance: round(total.pickup.distance / total.tripCount),
  }

  const wait = {
    time: round(total.wait.time / total.tripCount),
  }

  const delivery = {
    time: round(total.delivery.time / total.tripCount),
    distance: round(total.delivery.distance / total.tripCount),
  }

  return {
    trip: {
      time,
      distance,
      pay,
      providerPay,
      tipPay,
      pickup,
      wait,
      delivery
    }
  }
}

const userStatsCalculations = (total, user) => {
  const { pickup, wait, delivery, hourlyPay, perDistancePay, ...otherTotals} = total;

  const stageData = {
    pickup, 
    wait, 
    delivery
  }

  const incrementTotals = singleAddTotals(otherTotals, user);
  const newStageTotals = stageTotals(stageData, user);
  const calculatePayRates = payRateCalculator(incrementTotals);

  const userTotals = {
    ...incrementTotals,
    ...newStageTotals,
    ...calculatePayRates
  }

  const shiftAverages = shiftAverageCalculator(userTotals);
  const tripAverages = tripAverageCalculator(userTotals);

  const userAverages = {
    ...shiftAverages,
    ...tripAverages,
  }

  return {
    total: userTotals,
    average: userAverages
  }
}

export { userStatsCalculations }