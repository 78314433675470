import { useEffect } from 'react';
import { useTransition, useSpring } from 'react-spring';
import useRouter from '../customHooks/LocationContext';
import { 
  routeMap, 
  refreshTransition, 
  forwardTransition, 
  backwardTransition, 
  standardTransition,
} from './animationRouteLibrary';

function useLocationInfo() {
  const { location, history } = useRouter();

  return {location, history}
}

function shiftRouteDirection(previousId, currentId, isHistoryPop) {
  const direction = currentId - previousId;
  const delay = currentId === 1 & direction < 0 || previousId === 9 && currentId === 1;
  const determineDirection = direction < 0 && isHistoryPop ? backwardTransition(delay) : forwardTransition(delay);
  let finalDirection = {};
  
  if (currentId > 2 || (currentId === 2 && direction < 0)) {
    finalDirection = {};
  } else if (direction === 0) {
    finalDirection = refreshTransition
  } else {
    finalDirection = determineDirection;
  }

  return finalDirection;
}

function tripRouteDirection(previousId, currentId, isHistoryPop) {
  const direction = currentId - previousId;
  const delay = currentId === 1 & direction < 0 || previousId === 9 && currentId === 1;
  const determineDirection = direction < 0 && isHistoryPop ? backwardTransition(delay) : forwardTransition(delay);
  let finalDirection = determineDirection;

  return finalDirection;
}

 function useRoutingTransition(previousId, setPreviousId, shift = true) {
  const { location, history } = useLocationInfo();

  const isHistoryPop = history.action === 'POP';
  const currentId = routeMap.get(location.pathname);
  const direction = 
    shift 
    ? shiftRouteDirection(previousId, currentId, isHistoryPop)
    : tripRouteDirection(previousId, currentId, isHistoryPop)
  ;

  const finalAnimation = standardTransition(direction);

  useEffect(() => {
    setPreviousId(currentId || 1);
  }, [location])

  const transition = useTransition(
    location,
    {
      keys: location => location.key,
      ...finalAnimation
    }
  )

  return transition;
}

const useTripAnimation = () => {
  const {location, history} = useLocationInfo();
  const tripView = !!location.pathname.includes('completed-trips');
  const isDelay = history.action !== 'POP' || tripView;

  return useSpring(
    {
      from: {
        gridTemplateRows: tripView ? '1fr clamp(33%, 28vh, 35%)' : '1fr clamp(33%, 28vh, 35%)',
      },
      to: {
        gridTemplateRows: tripView ? '1fr clamp(33%, 100vh, 100%)' : '1fr clamp(33%, 28vh, 35%)',
      },
      delay: isDelay ? 400 : 0,
    }
  )
};

export { useRoutingTransition, useTripAnimation }