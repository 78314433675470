import React, { useState } from 'react'
import { useHistory } from 'react-router-dom';

export default function ProviderSelection({trip, style, currentUser, updateTrip, setStage}) {
  const nextPage = '/shift/active/trip-departure';
  const history = useHistory();
  const [orderProvider, setOrderProvider] = useState(trip.orderProvider);
  const clientList = currentUser ? currentUser.clientList : {};
  const stampInputs = [];

  const tripData = {
    stampInputs,
    orderProvider,
  };

  const submitOrderProvider = (e) => {
    e.preventDefault();

    updateTrip(tripData);
    setStage(nextPage);
    history.push(nextPage);
  }

  return (
    <div className = {style.stepCard}>
      <h4>Who gave you the Order?</h4>

      <form className={style.clientList} onSubmit = {submitOrderProvider}>
        <div>
          {clientList.map((client) =>(
            <div key ={client.displayName}>
              <input type="radio" name='clientList' id={client.displayName.replace(' ','').toLowerCase()}/>

              <label 
                onClick = {() => setOrderProvider(client.displayName)} 
                htmlFor={client.displayName.replace(' ','').toLowerCase()}
              >
                <img src={client.logoURL} alt="" />
              </label>
            </div>
          ))}
        </div>

        <button
         type='submit'
         disabled = {!orderProvider}
        >
          Submit Provider
        </button>
      </form>
    </div>
  )
}
