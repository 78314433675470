import React from 'react';
import { millisecondsToTimeConversion } from '../../../../helpers/AppHelpers';
import parentStyle from './../../stats.module.css';

export default function TotalTime({info, title}) {
  const activeTimePercentage = {
    width: `${info.activeTime / info.time * 100}%`,
    backgroundColor: 'green'
  }

  const inactiveTimePercentage = {
    width: `${(info.time - info.activeTime) / info.time * 100}%`,
    backgroundColor: 'red'
  }

  return (
    <div className={parentStyle.card}>
      <h3>{title || 'Total Time'}</h3>
      <div>
        <p>Total</p>
        <p>{millisecondsToTimeConversion(info.time)}</p>
        <div className = {parentStyle.percentBar}/>
      </div>

      <div>
        <p>Active</p>
        <p>{millisecondsToTimeConversion(info.activeTime)}</p>
        <div style = {activeTimePercentage} className = {parentStyle.percentBar}/>
      </div>

      <div>
        <p>Inactive</p>
        <p>{millisecondsToTimeConversion(info.time - info.activeTime)}</p>
        <div style ={inactiveTimePercentage} className = {parentStyle.percentBar}/>
      </div>
    </div>
  )
}