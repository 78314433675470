import React from 'react';
import useCurrencyInput from '../../../customHooks/CurrencyInput';
import { setSavedState } from '../../../helpers/trips/TripHelpers';
import { currencySubtraction, currencyAddition, round, hourlyPayCalulator, perDistancePayCalulator } from '../../../helpers/AppHelpers';
import { averageTotaledCalculations } from '../../../helpers/StatsHelpers';

export default function ActiveTripsEdit({currentTrip, setCurrentTrip, shift, setShift, style, setDisplayModal}) {
  const [tip, setTip] = useCurrencyInput('');

  const handleSubmit = (e) => {
    e.preventDefault();
    const newTip = tip;
    const tipDifference = currencySubtraction(newTip, currentTrip.total.tipPay);
    const newTotalPay = 
      currencyAddition(
        currentTrip.total.pay, (
          tipDifference
        )
      );
    const newPerDistancePay = round(newTotalPay / currentTrip.total.distance);
    const newRunRate = hourlyPayCalulator(currentTrip.total.time, newTotalPay);

    const newShiftTipPay = currencyAddition(shift.total.tipPay, tipDifference);
    const newShiftPay = currencyAddition(shift.total.pay, tipDifference);
    const newShiftHourly = hourlyPayCalulator(shift.total.time, newShiftPay);
    const newShiftDistancePay = perDistancePayCalulator(shift.total.distance, newShiftPay);

    const filteredTripList = shift.trips.filter((trip) => {
      return trip.id !== currentTrip.id
    })

    const updatedTotals = {
      ...currentTrip.total,
      tipPay: newTip,
      pay: newTotalPay,
      perDistancePay: newPerDistancePay,
      runRate: newRunRate,
    }

    const updatedShiftTotals = {
      ...shift.total,
      tipPay: newShiftTipPay,
      pay: newShiftPay,
      hourlyPay: newShiftHourly,
      perDistancePay: newShiftDistancePay,
    }

    const updatedShiftAverages = {
      ...shift.average,
      ...averageTotaledCalculations({
        tipPay: newShiftTipPay,
        pay: newShiftPay
      }, shift.total.tripCount)
    }

    const updatedTrip = {
      ...currentTrip,
      total: updatedTotals
    }

    const updatedTrips = [
      ...filteredTripList,
      updatedTrip
    ].sort((a , b) => a.id - b.id)

    const updatedShift = {
      ...shift,
      trips: updatedTrips,
      total: updatedShiftTotals,
      average: updatedShiftAverages
    }

    setCurrentTrip(updatedTrip);
    setShift(updatedShift);
    setDisplayModal(false);
    setSavedState({
      shift: updatedShift
    })

  }

  return (
    <div className={style.editForm}>
      <h4>Edit Tip Amount</h4>
      <form onSubmit={handleSubmit}>
        <label htmlFor = "tip">
              <input
                type = "number"
                name = "tip"
                placeholder = {`Original Tip $${currentTrip.total.tipPay}`}
                value = {tip}
                onChange = {e => {setTip(e.target.value)}}
                step = 'any'
                required
              />
          </label>
        <button type = 'submit'>Submit</button>
      </form>
    </div>
  )
}