const round = (number) => {
  return Number(Math.round(`${number}e2`)+`e-2`);
}

const currencyAddition = (amount1, amount2) => {
  return ((amount1 * 100 + amount2 * 100) / 100)
}

const currencySubtraction = (amount1, amount2) => {
  return ((amount1 * 100 - amount2 * 100) / 100)
}

const hourlyPayCalulator = (time, pay) => {
  return round((pay / (time / 3600000)))
}

const perDistancePayCalulator = (distance, pay) => {
  return (pay / distance)
}

const millisecondsToTimeConversion = (ms) => {
  const hours = Math.floor(ms / 3600000);
  const minutes = hours <= 0 ? Math.floor(ms / 60000) : Math.floor((ms / 60000)) % 60;
  const seconds = Math.floor(ms / 1000 / 60) > 0 ?  Math.floor((ms / 1000) % 60) : Math.floor((ms / 1000));

  const hasTimeOutput = hours || minutes || seconds;
  const timeOutput = !hours ? `${minutes}m ${seconds}s` : `${hours}h ${minutes}m`

  return hasTimeOutput ? timeOutput : '-'
}

const deepCopyCheat = (item) => {
  return JSON.parse(JSON.stringify(item));
}

const canProceedCalc = (previousStamp, currentOdometer) => {
  return parseFloat(previousStamp) <= parseFloat(currentOdometer);
}

const monthDayConverter = (date) => {
  const monthDate = new 
    Date(date)
    .toLocaleString(
      'en-US', {
        month: 'long',
        day: 'numeric',
  });

  return monthDate
}

const timeConverter = (date) => {
  const timeOutput = new 
    Date(date)
    .toLocaleString(
      'en-US', {
        hour: 'numeric',
        minute: 'numeric'
  });

  return timeOutput.toLocaleLowerCase()
}

export {
  round,
  currencyAddition,
  currencySubtraction,
  hourlyPayCalulator,
  perDistancePayCalulator,
  millisecondsToTimeConversion,
  deepCopyCheat,
  canProceedCalc,
  monthDayConverter,
  timeConverter
};


// Atomic Habits