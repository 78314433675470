import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

export default function OrderInfo({trip, style, updateTrip, setStage}) {
  const nextPage = '/shift/active/trip-select-provider';
  const history = useHistory();
  const [diner, setDiner] = useState(trip.diner || '');
  const [restaurant, setRestaurant] = useState(trip.restaurant || '');
  const stampInputs = [];

  const tripData = {
    stampInputs,
    diner,
    restaurant,
  }

  const submitOrderInfo = (e) => {
    e.preventDefault();

    updateTrip(tripData)
    setStage(nextPage);
    history.push(nextPage)
  }

  return (
    <div className = {style.stepCard}>
      <h4>Input Order Info</h4>

      <form onSubmit = {submitOrderInfo}>
        <label htmlFor = "diner">
          <input
            type = "text"
            name = "diner"
            placeholder = "Diner's Name"
            onChange = {e => setDiner(e.target.value)}
            value = {diner}
            required
          />
        </label>

        <label htmlFor = "restaurant">
          <input
            type = "text"
            name = "restaurant"
            placeholder = "Restaurant Name"
            onChange = {e => setRestaurant(e.target.value)}
            value = {restaurant}
            required
          />
        </label>

        <button
          type='submit'
          disabled = {!diner || !restaurant}
        >
          Submit Information
        </button>
      </form>
    </div>
  )
}
