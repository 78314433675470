import React from 'react';
import TotalTime from './totalStatsComponents/TotalTime';
import TotalDistance from './totalStatsComponents/TotalDistance';
import PayComparison from './totalStatsComponents/PayComparison';
import ActiveDistance from './totalStatsComponents/ActiveDistance';
import ActiveTime from './totalStatsComponents/ActiveTime';

export default function TotalStats({total}) {
  return (
    <>
      <TotalTime info = {total} />
      <TotalDistance info = {total}/>
      <PayComparison info = {total}/>
      <ActiveTime info = {total}/>
      <ActiveDistance info = {total}/>
    </>
  )
}