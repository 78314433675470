import React from 'react';
import TotalTime from './totalStatsComponents/TotalTime';
import TotalDistance from './totalStatsComponents/TotalDistance';
import PayComparison from './totalStatsComponents/PayComparison';
import ActiveDistance from './totalStatsComponents/ActiveDistance';
import ActiveTime from './totalStatsComponents/ActiveTime';

export default function ShiftStats({average}) {
  return (
    <>
      <TotalTime info = {average} title = {`Time per shift`}/>
      <TotalDistance info = {average} title = {`Distance per shift`}/>
      <PayComparison info = {average} title = {`Pay per shift`}/>
      <ActiveTime info = {average} title = {`Active time per shift`}/>
      <ActiveDistance info = {average} title = {`Active distance per shift`}/>
    </>
  )
}