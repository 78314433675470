import React from 'react';
import style from './averagedTotals.module.css';
import { millisecondsToTimeConversion } from '../../../../helpers/AppHelpers';

export default function AveragedTotals({shift}) {
  return (
    <div className={style.statContainer}>
      <div id={style.averagedPay}>
        <h4 className={style.statsHeader}>
          Pay Per Trip
        </h4>

        <div className={style.cardStat}>
          <p className={style.label}>Fare</p>
          <p className={style.stat}>${shift?.average?.providerPay}</p>
        </div>

        <div className={style.cardStat}>
          <p className={style.label}>Tip</p>
          <p className={style.stat}>${shift?.average?.tipPay}</p>
        </div>
          
        <div id={style.total} className={style.cardStat}>
          <p className={style.label}>Total</p>
          <p className={style.stat}>${shift?.average?.pay}</p>
        </div>
      </div>

      <div id={style.averagedMetrics}>
        <h4 className = {style.statsHeader}> Avg. Trip</h4>
          <div className={style.cardStat}>
            <p className={style.label}>Time</p>
            <p className={style.stat}>{millisecondsToTimeConversion(shift?.average?.activeTime)}</p>
        </div>

        <div className={style.cardStat}>
          <p className={style.label}>Distance</p>
          <p className={style.stat}>{shift?.average?.activeDistance || '-'}</p>
        </div>
      </div>
    </div>
  )
}