import React from 'react';
import ShiftStats from './ShiftStats';
import TripStats from './TripStats';

export default function AveragedStats({average}) {
  return (
    <>
      <ShiftStats average = {average.shift}/>
      <TripStats average = {average.trip}/>
    </>
  )
}