  const routeMap = new Map([
    ['/', 0],
    ['/shift/active', 1],
    ['/shift/break', 1.5],
    ['/shift/clock-out', 1.6],
    ['/shift/active/trip-start', 2],
    ['/shift/active/trip-pickup', 3],
    ['/shift/active/trip-order-info', 4],
    ['/shift/active/trip-select-provider', 5],
    ['/shift/active/trip-departure', 6],
    ['/shift/active/trip-orders', 7],
    ['/shift/active/trip-delivery', 8],
    ['/shift/active/trip-pay', 9],
  ])

  const refreshTransition = {
    from: {
      opacity: `0`,
    },
    enter: {
      opacity: '1'
    },
    leave: {
      opacity: `0`
    }
  };

  const forwardTransition = (delay = false) => ({
      from: {
        transform: `translate3d(100%, 0 ,0 )`
      },
      enter: {
        transform: 'translate3d(0%, 0 ,0 )',
        delay: delay ? 500 : 0
      },
      leave: {
        transform: `translate3d(-100%, 0 ,0 )`,
        delay: delay ? 500 : 0
      }
  });

  const backwardTransition = (delay = false) => ({
    from: {
      transform: `translate3d(-100%, 0 ,0 )`,
    },
    enter: {
      transform: 'translate3d(0%, 0 ,0 )',
      delay: delay ? 400 : 0
    },
    leave: {
      transform: `translate3d(100%, 0 ,0 )`,
      delay: delay ? 400 : 0
    }
  })

  const standardTransition = (direction) => {
    const transition = {};

    for (const [key, animation] of Object.entries(direction)) {
      transition[key] = animation
    }

    return transition;
  }

  export {
    routeMap,
    refreshTransition,
    forwardTransition,
    backwardTransition,
    standardTransition,
  };