import React from 'react';
import PayComparison from './totalStatsComponents/PayComparison';
import ActiveDistance from './totalStatsComponents/ActiveDistance';
import ActiveTime from './totalStatsComponents/ActiveTime';


export default function TripStats({average}) {
  return (
    <>
      <PayComparison info = {average} title = {`Pay per Trip`}/>
      <ActiveTime info = {average} title = {`Time per Trip`}/>
      <ActiveDistance info = {average} title = {`Distance per Trip`}/>
    </>
  )
}