import React, { useState, useEffect } from 'react';
import { Route } from 'react-router-dom';
import Signup from './Signup';
import Login from './Login';
import Profile from './Profile';
import ForgotPassword from './ForgotPassword';

export default function AuthFunctions({ auth, db, storage, currentUser, stage }) {
  const signUp = async (signUpInfo) => {
    const { email, password, firstName, lastName, clients, photo } = signUpInfo;

    const newUser = await auth.createUserWithEmailAndPassword(email, password);

    const userCollection = await db.collection('users').doc(newUser.user.uid);
    const storageRef = await storage.ref(`Users/${newUser.user.uid}/profilePhoto/profile.jpg`)

    await userCollection.set({
      firstName,
      lastName,
      clientList: clients,
      totalShifts: 0,
    });

    await storageRef.put(photo);
    const photoURL = await storageRef.getDownloadURL();

    return auth.currentUser.updateProfile({
      photoURL: photoURL,
    })
  };

  const login = async (email, password) => auth.signInWithEmailAndPassword(email, password);

  const logout = () => auth.signOut();

  const resetPassword = password => auth.sendPasswordResetEmail(password);

  const updateProfile = () => auth.updateEmail();

  const updatePassword = () => auth.updatePassword();

  const userState = {
    currentUser,
    logout,
    stage,
  };

  return (
    <>
      <Route path = "/account/signup">
        <Signup db = {db} signUp = {signUp}/>
      </Route>

      <Route path = "/account/login">
        <Login login = {login}/>
      </Route>

      <Route path = '/account/profile'>
        <Profile logout = {logout} />
      </Route>

      <Route path = "/account/forgot-password">
        <ForgotPassword resetPassword = {resetPassword}/>
      </Route>
    </>
  );
}