import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import useCurrencyInput from '../../../customHooks/CurrencyInput';
import usePersistenetState from './../../../customHooks/PersistentState';
import { round, hourlyPayCalulator, currencyAddition, currencySubtraction, canProceedCalc } from '../../../helpers/AppHelpers';

export default function PayInfo({trip, updateTrip, tripsSort, style, setStage}) {
  const uberTrip = trip.orderProvider.includes('Uber');
  const isDualTrip = trip.type !== 'standard';
  const currentTrips = tripsSort.active.length >= 2;
  const [providerPay, setProviderPay] = useCurrencyInput(trip?.total?.providerPay || '');
  const [tipPay, setTip] = useCurrencyInput(trip?.total?.tipPay || '');
  const [pay, setPay] = useCurrencyInput(trip?.total?.pay || '');
  const [dualPay, setDualPay] = usePersistenetState('dualpay', false);
  const completed = true;
  const canProceed = canProceedCalc(pay, providerPay);

  const determineLink = () => (currentTrips ? '/shift/active/trip-orders' : '/shift/active');
  const history = useHistory();

  const submitPayInfo = (e) => {
    e.preventDefault();

    if (canProceed) return;

    const dualPayCalc = 
      (currentTrips && uberTrip) || !isDualTrip
        ? {providerPay, tipPay, pay}
        : { providerPay: currencySubtraction(providerPay, dualPay[0]),
            tipPay: currencySubtraction(tipPay, dualPay[1]),
            pay: currencySubtraction(pay, dualPay[2])
          }

    const stampInputs = [];

    const total = {
      ...trip.total,
      providerPay: dualPayCalc.providerPay,
      tipPay: dualPayCalc.tipPay,
      pay: dualPayCalc.pay,
      perDistancePay: round(pay / trip.total.distance),
      runRate: hourlyPayCalulator(trip.total.time, pay),
    }

    const tripData = {
      completed,
      stampInputs,
      total,
    }

    setStage(determineLink());
    updateTrip(tripData);
    history.push(determineLink())
  }

  useEffect(() => {
    uberTrip 
      ? isDualTrip 
        ? setTip(currencySubtraction(currencySubtraction(pay, providerPay), dualPay[1]).toFixed(2))
        : setTip(currencySubtraction(pay, providerPay).toFixed(2))
      : setPay(currencyAddition(tipPay, providerPay).toFixed(2));

    if (currentTrips && uberTrip && isDualTrip) setDualPay([providerPay, tipPay, pay]);
  }, [tipPay, pay, providerPay])

  return (
    <div className = {style.stepCard}>
      <h4>Pay Breakdown</h4>
      <form onSubmit={submitPayInfo}>
        <label htmlFor = "provider">
          <input
            type = "number"
            name = "provider"
            placeholder = "Provider Contribution"
            value = {providerPay}
            onChange = {e => setProviderPay(e.target.value)}
            step = 'any'
            required
          />
        </label>

        {uberTrip
          ? 
          <label htmlFor = "total">
            <input
              type = "number"
              name = "total"
              placeholder = "Order Total"
              value = {pay}
              onChange = {e => setPay(e.target.value)}
              step = 'any'
              required
            />
        </label>
          :
          <label htmlFor = "tip">
            <input
              type = "number"
              name = "tip"
              placeholder = "Customer Tip"
              value = {tipPay}
              onChange = {e => setTip(e.target.value)}
              step = 'any'
              required
            />
        </label>
      }

        <button 
          type='submit'
          disabled = {(!tipPay || !pay || !providerPay) || canProceed}
        >
          Submit with {uberTrip ? 'Tip' : 'Total'} of ${uberTrip ? tipPay : pay}
          </button>
      </form>
    </div>
  )
}