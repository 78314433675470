import React from 'react'
import parentStyle from './../../stats.module.css';
import { round } from '../../../../helpers/AppHelpers';

export default function ActiveDistance({info, title}) {
  const totalDistance = info.activeDistance || info.distance;

  const pickupPercentage = {
    width: `${info.pickup.distance / totalDistance * 100}%`,
    backgroundColor: 'red'
  }

  const deliveryPercentage = {
    width: `${(info.delivery.distance / totalDistance * 100)}%`,
    backgroundColor: 'green'
  }

  return (
    <div className={parentStyle.card}>
      <h3>{title || 'Active Distance'}</h3>
      <div>
        <p>Total</p>
        <p>{round(totalDistance)} miles</p>
        <div className = {parentStyle.percentBar}/>
      </div>

      <div>
        <p>Pickup</p>
        <p>{round(info.pickup.distance)} miles</p>
        <div style = {pickupPercentage} className = {parentStyle.percentBar}/>
      </div>

      <div>
        <p>Delivery</p>
        <p>{round(info.delivery.distance)} miles</p>
        <div style = {deliveryPercentage} className = {parentStyle.percentBar}/>
      </div>
    </div>
  )
}