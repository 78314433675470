import React from 'react';
import { createStamp } from './../../helpers/trips/TripHelpers';
import useDistanceInput from '../../customHooks/DistanceInput';
import { canProceedCalc } from '../../helpers/AppHelpers';
import { useHistory } from 'react-router';

export default function EndShift({ shift, updateShift, setStage, style}) {
  const [odometer, setOdometer] = useDistanceInput('');
  const stampDate = Date.now();
  const previousStamp = shift?.total?.distance;
  const canProceed = canProceedCalc(previousStamp, odometer);
  const nextPage = '/';
  const history = useHistory();

  const endShift = (e) => {
    e.preventDefault();
    
    const timeStamp = createStamp({
      title: 'time',
      stampValue: stampDate,
      stage: 'endShift',
      placement: 'end'
    })

    const odomStamp = createStamp({
      title: 'distance',
      stampValue: odometer,
      stage: 'endShift',
      placement: 'end'
    })

    const stampInputs = [timeStamp, odomStamp];
    const completed = true;

    const end = {
      time: stampDate,
      distance: odometer
    }

    const total = {
      ...shift.total,
      time: end.time - shift.start.time,
      distance: end.distance - shift.start.distance
    }

    const shiftData = {
      completed,
      end,
      total,
      stampInputs
    }

    setStage(nextPage);
    updateShift(shiftData);
    history.push('/');
  };

  return (
    <div className = {style.stepCard}>
      <h4>End Shift</h4>

      <form onSubmit={endShift}>
        <label htmlFor = "odometer-end">
          <input
            type = "number"
            name = "odometer-end"
            value = {odometer}
            placeholder = "Current Odometer"
            onChange = {e => setOdometer(e.target.value)}
          />
        </label>

        <button 
          type='submit'
          disabled = {!canProceed || !odometer}
          >
          Clock Out
        </button>
      </form>
    </div>
  );
}