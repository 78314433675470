import React, {useState} from 'react';
import style from './tripView.module.css';
import ActiveTripsEdit from './ActiveTripsEdit';
import { monthDayConverter,
  timeConverter,
  millisecondsToTimeConversion,
  round
} from '../../../helpers/AppHelpers';
import { AiFillEdit } from 'react-icons/ai';
import ActiveTime from '../../stats/LifeTimeStats/totalStatsComponents/ActiveTime';
import ActiveDistance from '../../stats/LifeTimeStats/totalStatsComponents/ActiveDistance';
import PayComparison from '../../stats/LifeTimeStats/totalStatsComponents/PayComparison';

export default function ViewTrip({viewableTripId, shift, setShift}) {
  const [currentTrip, setCurrentTrip] = useState(shift.trips.find((trip) => trip.id === viewableTripId));
  const [displayModal, setDisplayModal] = useState(false);

  const pluralDistance = (distance) => {
   const wordUse = distance === 1 ? 'mile' : 'miles';

   return `${round(distance)} ${wordUse}`
  }

  const sectionHeight = () => {
    const pickup = (currentTrip.pickup.total.time / currentTrip.total.time) * 10;
    const wait = (currentTrip.wait.total.time / currentTrip.total.time) * 10;
    const delivery = (currentTrip.delivery.total.time / currentTrip.total.time) * 10;

    const heights = {
      gridTemplateRows: `auto ${pickup}fr auto ${wait}fr auto ${delivery}fr auto`
    }

    return heights
  }

  const state = {
    viewableTripId,
    currentTrip,
    setCurrentTrip,
    shift,
    setShift,
    style,
    setDisplayModal
  }

  const stageData = {
    time: currentTrip.total.time,
    distance: currentTrip.total.distance,
    pickup: {...currentTrip.pickup.total},
    wait: {...currentTrip.wait.total},
    delivery: {...currentTrip.delivery.total}
  }

  return (
    <div className = {style.tripViewContainer}>
      <div className={style.tripViewHeader}>
        <div className={style.logoContainer}>
          <p className = {style.logo}>{currentTrip.orderProvider.split(/(?=[A-Z])/).join(' ')}</p>
        </div>

        <div className={style.tripInfo}>
          <h3>{monthDayConverter(currentTrip.start.time)}</h3>
          <p>
            <span className = {style.capitalize}>{currentTrip.restaurant}</span>
            <span className = {style.capitalize}>{currentTrip.type}</span>
          </p>
        </div>

        <div className={style.tripPayInfo}>
          <h3>Pay Rates</h3>
          <p><b>${currentTrip.total.runRate} </b>/hour</p>
          <p><b>${currentTrip.total.perDistancePay} </b>/mile</p>
        </div>
      </div>

      <div className={style.statBarsContainer}>
        <PayComparison info = {currentTrip.total} title = {'Trip Pay'} trip = {true}/>
        <ActiveTime info = {stageData} title = {'Trip Time'}/>
        <ActiveDistance info = {stageData} title = {'Trip Distance'}/>
      </div>

      <div className = {style.timeLineContainer}>
        <div className={style.timeLineHeading}>
          <h3>Trip Timeline</h3>
        </div>

        <div className={style.timeLine} style = {sectionHeight()}>
          <div className={style.startTime}>
            <h4>Order Recieved</h4>
            <p>- {timeConverter(currentTrip.start.time)}</p>
          </div>
          
          <div className={style.pickup}>
            <p>
              Drove <b>{pluralDistance(currentTrip.pickup.total.distance)} </b>
              in <b>{millisecondsToTimeConversion(currentTrip.pickup.total.time)}</b>
            </p>
          </div>
            
          <div className={style.pickupArrival}>
            <h4>Arrived at <i>{currentTrip.restaurant}</i></h4>
            <p>- {timeConverter(currentTrip.pickup.end.time)}</p>
          </div>

          <div className={style.wait}>
            <p>Waited for <b>{millisecondsToTimeConversion(currentTrip.wait.total.time)}</b></p>
          </div>

          <div className={style.pickupDeparture}>
            <h4>Left <i>{currentTrip.restaurant}</i></h4>
            <p>- {timeConverter(currentTrip.delivery.start.time)}</p>
          </div>

          <div className={style.delivery}>
            <p>
              Drove <b>{pluralDistance(currentTrip.delivery.total.distance)} </b>
              in <b>{millisecondsToTimeConversion(currentTrip.delivery.total.time)}</b>
            </p>
          </div>

          <div className={style.endTime}>
            <h4>Order Delivered</h4>
            <p>- {timeConverter(currentTrip.end.time)}</p>
          </div>
        </div>
      </div>

      {currentTrip.orderProvider === 'UberEats' &&
        <div 
          className={style.editButton}
          onClick = {() => setDisplayModal(!displayModal)}
        >
          <AiFillEdit />
        </div>
      }

      {displayModal && <ActiveTripsEdit {...state}/>}
    </div>
  )
}