import React, {useState, useEffect} from 'react';
import { Route, Switch } from 'react-router-dom';
import { getSavedState } from '../../../helpers/trips/TripHelpers';

import ViewTrip from './ViewTrip';
import ActiveTripsList from './ActiveTripsList';

export default function ActiveTripsRouter({shift, setShift}) {
  const [viewableTripId, setViewableTripId] = useState(getSavedState('viewableTripId'));

  const activeTripsState = {
    viewableTripId,
    setViewableTripId,
    shift,
    setShift
  }

  return (
    <> 
      <Switch>
        <Route exact path = '/shift/completed-trips'>
          <ActiveTripsList {...activeTripsState} />
        </Route>

        <Route path='/shift/completed-trips/view-trip'>
          <ViewTrip {...activeTripsState} />
        </Route>
      </Switch>
    </>
  )
}