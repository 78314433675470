import React from 'react';
import DistanceInputError from '../../micsComponents/DistanceInputError';
import { useHistory } from 'react-router-dom';
import { createStamp } from '../../../helpers/trips/TripHelpers';
import useDistanceInput from '../../../customHooks/DistanceInput';
import { canProceedCalc } from '../../../helpers/AppHelpers';

export default function Pickup({ 
  trip,
  updateTrip,
  setStage,
  updateShiftStageId,
  style
}) {
  const [odometer, setOdometer] = useDistanceInput(trip?.pickup?.end?.distance || '');
  const nextPage = '/shift/active/trip-order-info';
  const history = useHistory();
  const previousStamp = trip?.pickup?.start?.distance;
  const canProceed = canProceedCalc(previousStamp, odometer);

  const updateTripPickup = (e) => {
    e.preventDefault();

    if (!canProceed) {
      return;
    }
    
    const stampDate = Date.now();

    const timeStamp = createStamp({
      title: 'time', 
      stampValue: stampDate, 
      stage: 'pickup'
    });
  
    const odomStamp = createStamp({
      title: 'distance', 
      stampValue: odometer, 
      stage: 'pickup'
    });
  
    const end = {
      time: stampDate,
      distance: odometer
    }
  
    const total = {
      time: end?.time - trip?.start?.time,
      distance: end?.distance- trip?.start?.distance
    }
  
    const stampInputs = [timeStamp, odomStamp];
  
    const tripData = {
      stampInputs,
      end,
      total
    };

    updateTrip(tripData);
    updateShiftStageId(null);
    setStage(nextPage);
    history.push(nextPage);
  };

  const errorData = {
    previousStamp,
    canProceed
  }

  return (
    <div className = {style.stepCard}>
      <h4>Pickup Arrival</h4>

      <form onSubmit={updateTripPickup}>
        <label htmlFor = "odometer">

        {odometer.length > 2 && <DistanceInputError {...errorData} />}

          <input
            type = "number"
            name = "odometer"
            placeholder = "Current Odometer"
            onChange = {e => setOdometer(e.target.value)}
            value = {odometer}
            step = 'any'
            required
          />
        </label>

        <button 
          type = "submit"
          disabled = {!odometer || !canProceed}
        >
            Arrived For Pickup
        </button>
      </form>
    </div>
  );
}