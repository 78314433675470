import React from 'react'
import { millisecondsToTimeConversion } from '../../../../helpers/AppHelpers'
import parentStyle from './../../stats.module.css';

export default function ActiveTime({info, title}) {
  const totalTime = info.activeTime || info.time;
  
  const pickupPercentage = {
    width: `${info.pickup.time / totalTime * 100}%`,
    backgroundColor: 'orange'
  } 

  const waitPercentace = {
    width: `${info.wait.time / totalTime * 100}%`,
    backgroundColor: 'red'
  }

  const deliveryPercentage = {
    width: `${(info.delivery.time / totalTime * 100)}%`,
    backgroundColor: 'green'
  }

  return (
    <div className={parentStyle.card}>
      <h3>{title || 'Active Time'}</h3>
      <div>
        <p>Total</p>
        <p>{millisecondsToTimeConversion(totalTime)}</p>
        <div className = {parentStyle.percentBar}/>
      </div>

      <div>
        <p>Pickup</p>
        <p>{millisecondsToTimeConversion(info.pickup.time)}</p>
        <div style = {pickupPercentage} className = {parentStyle.percentBar}/>
      </div>

      <div>
        <p>Wait</p>
        <p>{millisecondsToTimeConversion(info.wait.time)}</p>
        <div style = {waitPercentace} className = {parentStyle.percentBar}/>
      </div>

      <div>
        <p>Delivery</p>
        <p>{millisecondsToTimeConversion(info.delivery.time)}</p>
        <div style = {deliveryPercentage} className = {parentStyle.percentBar}/>
      </div>
    </div>
  )
}