import { round } from "../AppHelpers";

const getSavedState = item => JSON.parse(localStorage.getItem(item));

const setSavedState = (state) => {
  for (const [key, value] of Object.entries(state)) {
    localStorage.setItem(key, JSON.stringify(value));
  }
};

const createStamp = ({title, stampValue, stage, placement = 'end', switchTrigger = false}) => {
 return {
  title,
  stampValue,
  stage,
  placement,
  switchTrigger
 }
}

const tripSort = (trips) => {
  const active = trips.filter(trip => trip.completed === false);
  const completed = trips.filter(trip => trip.completed === true);
  const total = trips;

  return { active, total, completed };
};

const sequenceDoubleUp = (sequenceData) => {
  const {
    sortedState,
    sortProps,
    createTrip,
    tripsSort,
    stamps,
    createStamps } = sequenceData;

    const { trip, trips } = sortProps;
    const {sortedStamps} = tripsSort.active.length < 2 ? {sortedStamps: stamps}: sortedState(sortProps);

    const wait = (() => {
      const splitWaitTime = sortedStamps.total.time / 2;

      return {
        start: sortedStamps.start,
        end: sortedStamps.end,
        total: {
          time: splitWaitTime
        }
      }
    })();

    const total = (() => {
      const splitTime = round(sortedStamps.total.time / 2);
      const splitDistance = round(sortedStamps.total.distance / 2);

      return {
        time: splitTime,
        distance: splitDistance
      }
    })();

    const pickup = (() => {
      const splitPickupTime = round(trip.pickup.total.time / 2);
      const splitPickupDistance = round(trip.pickup.total.distance / 2);

      const total = {
        time: splitPickupTime, 
        distance: splitPickupDistance
      }

      return {
        ...trip.pickup,
        total,
      }
    })();

    const splitTrip = {...trip, pickup, total, wait, type: 'doubleUp'};
    const filteredTrips = trips.filter((trip) => {
      return trip.id !== splitTrip.id
    })

    const tripToUpdateWith = tripsSort.active.length < 2 ? {...createTrip(tripsSort.total.length)} : splitTrip;

    const updatedTrip = {
      ...tripToUpdateWith,
      start: splitTrip.start,
      pickup,
      wait,
      total: splitTrip.total,
      type: 'doubleUp'
    };

    const updatedTrips =  tripsSort.active.length < 2 ? [
      ...filteredTrips,
      {...splitTrip},
      {...updatedTrip}
    ] : [
      ...filteredTrips,
      {...updatedTrip}
    ]

    return {updatedTrip, updatedTrips, sortedStamps};
}

const sequenceAddOn = (sequenceData) => {
  const {
    sortedState,
    sortProps,
    createTrip,
    tripsSort,
    stamps,
    createStamps } = sequenceData;

  const { updatedTrips } = sortedState(sortProps);
  const newTrip = createTrip(tripsSort.total.length);

  const start = {
    time: stamps?.end?.time,
    distance: stamps?.end?.distance
  }

  const stampInputs = [
    createStamp({
      title: 'distance',
      stampValue: start.distance,
      stage: 'pickup',
      placement: 'start',
      switchTrigger: true
  }),

    createStamp({
      title: 'time',
      stampValue: start.time,
      stage: 'pickup',
      placement: 'start',
      switchTrigger: true
    }),
  ];

  const newTripData = {
    stampInputs,
    stamps: createStamps(),
    trip: newTrip,
    trips: updatedTrips,
    start
  };

  return sortedState(newTripData, 'addOn');
};

const waitStageDeepCopyCheat = (item) => {
  const copy = JSON.parse(JSON.stringify(item));

  delete copy.start.distance;
  delete copy.end.distance;
  delete copy.total.distance;

  return copy;
}

export {
  getSavedState,
  setSavedState,
  createStamp,
  tripSort,
  sequenceAddOn,
  waitStageDeepCopyCheat,
  sequenceDoubleUp
};