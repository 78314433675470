import React from 'react';
import { useHistory } from 'react-router-dom';
import { createStamp } from '../../../helpers/trips/TripHelpers';
import { FaPlus, FaArrowRight, FaCheckDouble } from 'react-icons/fa';

export default function Departure({trip, updateTrip, tripsSort, setStage, style }) {
  const history = useHistory();
  const maxTrips = !(tripsSort.active.length >= 2);
  const headOutLink = () => (maxTrips ? '/shift/active/trip-delivery' : '/shift/active/trip-orders');

  const calculateTimeData = () => {
    const stampDate = Date.now();

    const timeStamp = createStamp({
      title: 'time', 
      stampValue: stampDate, 
      stage: 'wait'});
    
    const stampInputs = [timeStamp];

    const tripData = {
      stampInputs,
    }

    return {stampDate, tripData};
  }

  const doubleUp = (e) => {
    e.preventDefault();
    const { tripData } = calculateTimeData();
    const isMultiTrip = 'doubleUp';
    const nextPage = '/shift/active/trip-order-info';

    const AddonData = {
      ...tripData,
      isMultiTrip,
      type: isMultiTrip,
    };

    setStage(nextPage);
    updateTrip(AddonData);
    !maxTrips ? history.push(headOutLink()) : history.push(nextPage);
  }

  const addTrip = (e) => {
    e.preventDefault();
    const { tripData } = calculateTimeData();
    const isMultiTrip = 'addOn';
    const nextPage = '/shift/active/trip-pickup';

    const AddonData = {
      ...tripData,
      isMultiTrip,
      type: isMultiTrip,
    };

    setStage(nextPage);
    updateTrip(AddonData);
    history.push(nextPage);
  };

  const updateTripDeparture = (e) => {
    e.preventDefault();
    const { stampDate, tripData } = calculateTimeData();
    setStage(headOutLink());
    
    const end = {
      ...trip.end,
      time: stampDate
    }

    const total = {
      ...trip.total,
      time: end?.time - trip.start.time,
    }

    const updateData = {
      ...tripData,
      total
    }

    updateTrip(updateData);
    history.push(headOutLink());
  };

  return (
    <div className = {style.stepCard} id = {style.actionButtons}>
      <h4>Pickup Departure</h4>

      {maxTrips && (
        <>
          <form id = {style.addTrip} onSubmit = {addTrip}>
            <button type = 'submit'>Add Trip</button>
            <div className={style.buttonIcon}>
              <FaPlus />
            </div>
          </form>

          <form id = {style.doubleUp} onSubmit = {doubleUp}>
            <button type = 'submit'>Double Up</button>
            <div className={style.buttonIcon}>
              <FaCheckDouble />
            </div>
          </form>
        </>
      )}

      <form id = {style.headOut} onSubmit = {trip.type ==='doubleUp' ? doubleUp : updateTripDeparture}>
        <button type = 'submit'>Head Out</button>
        <div className={style.buttonIcon}>
          <FaArrowRight />
        </div>
      </form>
    </div>
  );
}