import React, { useState } from 'react'

export default function useCurrencyInput(initialValue) {
  const [formattedInput, setInput] = useState(initialValue);

  const updateInput = (input) => {
    setInput((input.toString().replace('.', '') / 100).toFixed(2));
  }
  
  return [formattedInput, updateInput]
}
