import React from 'react';
import { useHistory } from 'react-router-dom';
import NewTrip from './trip/00-NewTrip';
import { FaPause, FaStop } from 'react-icons/fa';

export default function MainShift({ updateTrip, trip, setStage, style}) {
  const history = useHistory();

  const handleSubmit = (e, link) => {
    e.preventDefault();
    return history.push(link);
  }

  return (
    <div className = {style.stepCard} id = {style.actionButtons}>
      <h4>Shift Options</h4>

        { trip.completed &&
        <>
          <form id={style.pause} onSubmit = {e => handleSubmit(e, "/shift/break")}>
            <button>
              Pause Shift
            </button>
            <div className={style.buttonIcon}>
              <FaPause />
            </div>
          </form>

          <form id={style.end} onSubmit = { e => handleSubmit(e, "/shift/clock-out")}>
            <button type='submit'>End Shift</button>
            <div className={style.buttonIcon}>
              <FaStop />
            </div>
          </form>
        </>
        }

        <NewTrip
          updateTrip = {updateTrip}
          trip = {trip}
          setStage = {setStage}
          style = {style}
        />
    </div>
  );
}