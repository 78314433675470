import React from 'react'
import { round } from '../../../../helpers/AppHelpers';
import parentStyle from './../../stats.module.css';

export default function TotalDistance({info, title}) {
  const activeDistancePercentage = {
    width: `${info.activeDistance / info.distance * 100}%`,
    backgroundColor: 'green'
  } 

  const InactiveDistancePercentage = {
    width: `${((info.distance - info.activeDistance) / info.distance * 100) || '5'}%`,
    backgroundColor: 'red'
  }
  
  return (
    <div className={parentStyle.card}>
      <h3>{title || 'Total Distance'}</h3>
      <div>
        <p>Total</p>
        <p>{info.distance} miles</p>
        <div className = {parentStyle.percentBar}/>
      </div>
      <div>
        <p>Active</p>
        <p>{info.activeDistance} miles</p>
        <div style ={activeDistancePercentage} className = {parentStyle.percentBar}/>
      </div>
      <div>
        <p>Inactive</p>
        <p>{round(info.distance - info.activeDistance)} miles</p>
        <div style ={InactiveDistancePercentage} className = {parentStyle.percentBar}/>
      </div>
    </div>
  )
}