import React from 'react'
import parentStyle from './../../stats.module.css';

export default function PayComparison({info, title, trip}) {
  const determinePluralUse = (word) => {
    return !trip ? word + 's' : word;
  }

  const faresPercentage = {
    width: `${info.providerPay / info.pay * 100}%`,
    backgroundColor: 'orange'
  } 

  const tipsPercentage = {
    width: `${(info.tipPay / info.pay * 100) || '5'}%`,
    backgroundColor: 'green'
  }

  return (
    <div className={`${parentStyle.payComparison} ${parentStyle.card}`}>
      <h3>{title || 'Total Pay'}</h3>

      <div>
        <p>Total</p>
        <p>${parseFloat(info.pay).toFixed(2)}</p>
        <div className = {parentStyle.percentBar}/>
      </div>

      <div>
        <p>{determinePluralUse('Fare')}</p>
        <p>${parseFloat(info.providerPay).toFixed(2)}</p>
        <div style = {faresPercentage} className = {parentStyle.percentBar}/>
      </div>

      <div>
        <p>{determinePluralUse('Tip')}</p>
        <p>${parseFloat(info.tipPay).toFixed(2)}</p>
        <div style = {tipsPercentage} className = {parentStyle.percentBar}/>
      </div>
    </div>
  )
}