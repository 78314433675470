import React from 'react';
import DistanceInputError from '../../micsComponents/DistanceInputError';
import { useHistory } from 'react-router-dom';
import { createStamp } from '../../../helpers/trips/TripHelpers';
import useDistanceInput from '../../../customHooks/DistanceInput';
import { canProceedCalc } from '../../../helpers/AppHelpers';

export default function EndTrip({ 
  trip, 
  updateTrip, 
  tripsSort, 
  switchTrigger, 
  setStage, 
  updateShiftStageId, 
  shiftStageId,
  stamps,
  style
}) {
  const [odometer, setOdometer] = useDistanceInput(trip?.delivery?.end?.distance || '');
  const nextPage = '/shift/active/trip-pay';
  const history = useHistory();
  const stageId = shiftStageId ==  null ? 
    trip.id.toString() : 
    shiftStageId.concat(`-${trip.id}`)
    .split('-')
    .sort()
    .join('-');
  const currentTrips = tripsSort.active.length >= 2;
  const triggerToggle = !currentTrips;
  
  const previousStamp = trip.pickup.end.distance;
  const canProceed = canProceedCalc(previousStamp, odometer);

  const orderDelivered = (e) => {
    e.preventDefault();

    if (!canProceed) return

    const stampDate = Date.now();

    const time = (stampDate - stamps.end.time) + trip.pickup.total.time + trip.wait.total.time;
    const distance = (odometer - stamps.end.distance) + trip.pickup.total.distance;

    const timeStamp = createStamp({
      title: 'time',
      stampValue: Date.now(),
      stage: 'delivery',
      switchTrigger: triggerToggle
    });
  
    const odomStamp = createStamp({
      title: 'distance',
      stampValue: odometer,
      stage: 'delivery',
      switchTrigger: triggerToggle
    });
  
    const stampInputs = [timeStamp, odomStamp];

    const end = {
      time: stampDate,
      distance: odometer
    }
  
    const total = {
      ...trip.total,
      time,
      distance,
    }
  
    const tripData = {
      stampInputs,
      switchTriggerToggle: triggerToggle,
      end,
      total
    };

    setStage(nextPage);
    updateShiftStageId(stageId);
    updateTrip(tripData);
    history.push(nextPage);
  };

  const errorData = {
    previousStamp,
    canProceed
  }

  return (
    <div className = {style.stepCard}>
      <h4>Delivered</h4>

      <form onSubmit={orderDelivered}>
        <label htmlFor = "odometer">

          {odometer.length > 2 && <DistanceInputError {...errorData} />}

          <input
            type = "number"
            name = "odometer"
            placeholder = "Current Odometer"
            value = {odometer}
            onChange = {e => setOdometer(e.target.value)}
            step = 'any'
            required
          />
        </label>

        <button 
          type = "submit"
          disabled = {!odometer && !canProceed}
        >
          Delivered
        </button>
      </form>
    </div>
  );
}