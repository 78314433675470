import React, { useState } from 'react'

export default function useDistanceInput(initialValue) {
  const [formattedInput, setInput] = useState(initialValue);

  const updateInput = (input) => {
    setInput((input.replace('.', '') / 10).toFixed(1));
  }
  
  return [formattedInput, updateInput]
}