import { deepCopyCheat } from "../AppHelpers";
import { waitStageDeepCopyCheat } from "./TripHelpers";
import { round } from "../AppHelpers";

const stampManager = (stamps, stampInputs) => {
  const currentStage = stamps.stage;
  const stampCopy = stamps;
  
  stampInputs.forEach((stamp) => {
    const { title, stampValue, stage, placement, switchTrigger } = stamp;

    if (stampValue) {
      const determineSliceBehavior = () => currentStage === stage && !switchTrigger;
      const takeFirstStamp = () => (stampCopy?.start?.[title]);
      const takeLastStamp = () => (stampCopy?.end?.[title]);

      const stampSet = determineSliceBehavior() ? takeFirstStamp() : takeLastStamp();

      stampCopy.start[title] = stampSet;
      stampCopy.stage = stage;
      stampCopy[placement][title] = stampValue;

      if (stampCopy?.end?.[title]) {
        stampCopy.total[title] = round(stampCopy.end[title] - stampCopy.start[title]);
      }

    }
  });

  return stampCopy;
};

const setUpdatedTrip = (trip, tripInfo, stampManager) => {

  const tripCopy = { ...trip, ...tripInfo };
  const {stage, ...stamps} = stampManager

  if (tripCopy.stageInfo) {
    // const stageInfoItem = {[stage]: stamps};
    
    // const deDuplicatedStageinfo = tripCopy.stageInfo.filter((filteredStamp) => {
    //   return Object.keys(filteredStamp)[0] !== stage;
    // })
    return tripCopy;
  } else {
    tripCopy[stage] = stage !== 'wait' ?  deepCopyCheat(stamps) : waitStageDeepCopyCheat(stamps);
  }

  return tripCopy;
};

const setUpdatedTrips = (trips, trip) => {
  const determineTripPlacement = () => {
    const tripMatch = trips.find(eachTrip => eachTrip.id === trip.id);

    return !!tripMatch;
  };

  const replaceTripsArray = () => {
    const updatedTrips = [...trips];
    updatedTrips[trip.id] = trip;

    return updatedTrips;
  };

  const updateTripsArray = () => {
    const updatedTrips = [...trips, trip];

    return updatedTrips;
  };

  return determineTripPlacement() ? replaceTripsArray() : updateTripsArray();
};

export default function sortedState(tripData) {
  const { stamps, trip, trips, stampInputs, ...tripProps } = tripData;

  const sortedStamps = stampManager(stamps, stampInputs, trip.id);
  const updatedTrip = setUpdatedTrip(trip, tripProps, sortedStamps);
  const updatedTrips = setUpdatedTrips(trips, updatedTrip);

  return { sortedStamps, updatedTrip, updatedTrips };
}