import React, {useState, useEffect} from 'react';
import style from './../shift/activeTrips/tripView.module.css';
import {
  monthDayConverter,
  timeConverter,
  round,
  millisecondsToTimeConversion
} from '../../helpers/AppHelpers';
import PayComparison from './LifeTimeStats/totalStatsComponents/PayComparison';
import ActiveTime from './LifeTimeStats/totalStatsComponents/ActiveTime';
import ActiveDistance from './LifeTimeStats/totalStatsComponents/ActiveDistance';

export default function TripView({tripId, tripsPath}) {
  const [isLoading, setIsLoading] = useState(true);
  const [trip, setTrip] = useState({});

  const getTrip = async () => {
    setIsLoading(true);
    console.log(tripsPath)
    const getTrip = await tripsPath.doc(`trip-${tripId}`).get();
    console.log(getTrip.data());

    setTrip(getTrip.data());
    setIsLoading(false);
  }

  useEffect(() => {
    getTrip();
  }, []);

  const pluralDistance = (distance) => {
    const wordUse = distance === 1 ? 'mile' : 'miles';
 
    return `${round(distance)} ${wordUse}`
   }

  const sectionHeight = () => {
    const pickup = (trip.pickup.total.time / trip.total.time) * 10;
    const wait = (trip.wait.total.time / trip.total.time) * 10;
    const delivery = (trip.delivery.total.time / trip.total.time) * 10;

    const heights = {
      gridTemplateRows: `auto ${pickup}fr auto ${wait}fr auto ${delivery}fr auto`
    }

    return heights
  }

  const stageData = {
    time: trip?.total?.time,
    distance: trip?.total?.distance,
    pickup: {...trip?.pickup?.total},
    wait: {...trip?.wait?.total},
    delivery: {...trip?.delivery?.total}
  }

  return (
    <>
      {!isLoading &&
        <div style = {{gridColumn: '1/-1', gridRow: '1/-1'}} className = {style.tripViewContainer}>
        <div className={style.tripViewHeader}>
          <div className={style.logoContainer}>
            <p className = {style.logo}>{trip.orderProvider.split(/(?=[A-Z])/).join(' ')}</p>
          </div>

          <div className={style.tripInfo}>
            <h3>{monthDayConverter(trip.start.time)}</h3>
            <p>
              <span>{trip.restaurant}</span>
              <span className = {style.capitalize}>{trip.type}</span>
            </p>
          </div>

          <div className={style.tripPayInfo}>
            <h3>Total: ${trip.total.pay}</h3>
            <p><b>Fare:</b> ${trip.total.providerPay}</p>
            <p><b>Tip:</b> ${trip.total.tipPay}</p>
          </div>
        </div>

        <div className={style.statBarsContainer}>
          <PayComparison info = {trip.total} title = {'Trip Pay'} trip = {true}/>
          <ActiveTime info = {stageData} title = {'Trip Time'}/>
          <ActiveDistance info = {stageData} title = {'Trip Distance'}/>
        </div>

        <div className = {style.timeLineContainer}>
          <div className={style.timeLineHeading}>
            <h3>Trip Timeline</h3>
          </div>

          <div className={style.timeLine} style = {sectionHeight()}>
            <div className={style.startTime}>
              <h4>Order Recieved</h4>
              <p>- {timeConverter(trip.start.time)}</p>
            </div>
            
            <div className={style.pickup}>
              <p>
                Drove <b>{pluralDistance(trip.pickup.total.distance)} </b>
                in <b>{millisecondsToTimeConversion(trip.pickup.total.time)}</b>
              </p>
            </div>
              
            <div className={style.pickupArrival}>
              <h4>Arrived at <i>{trip.restaurant}</i></h4>
              <p>- {timeConverter(trip.pickup.end.time)}</p>
            </div>

            <div className={style.wait}>
              <p>Waited for <b>{millisecondsToTimeConversion(trip.wait.total.time)}</b></p>
            </div>

            <div className={style.pickupDeparture}>
              <h4>Left <i>{trip.restaurant}</i></h4>
              <p>- {timeConverter(trip.delivery.start.time)}</p>
            </div>

            <div className={style.delivery}>
              <p>
                Drove <b>{pluralDistance(trip.delivery.total.distance)} </b>
                in <b>{millisecondsToTimeConversion(trip.delivery.total.time)}</b>
              </p>
            </div>

            <div className={style.endTime}>
              <h4>Order Delivered</h4>
              <p>- {timeConverter(trip.end.time)}</p>
            </div>
          </div>
        </div>
      </div>
      }
    </>
  )
}