import React from 'react';
import style from './payRates.module.css';

export default function PayRates({shift}) {
  const hourlyPay = isNaN(shift?.total?.hourlyPay) ? '0.00' : shift?.total?.hourlyPay;
  const distancePay = isNaN(shift?.total?.perDistancePay) ? '0.00' : shift?.total?.perDistancePay;

  return (
    <div className={style.statContainer}>
      <div>
        <h4 className={style.statsHeader}>
          Hourly Pay
        </h4>
        <div className={style.cardStat}>
          <p className = {style.stat}>${hourlyPay}</p>
          <p className = {style.label}>/ hour</p>
        </div>
      </div>

      <div>
        <h4 className={`${style.statsCard} ${style.statsHeader}`}>
          Pay Per Mile
        </h4>
        <div className={style.cardStat}>
          <p className={style.stat}>${distancePay}</p>
          <p className={style.label}>/ mile</p>
        </div>
      </div>
    </div>
  )
}