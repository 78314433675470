import React, { useEffect, useState } from 'react';
import { Route } from 'react-router';
import { Link } from 'react-router-dom';
import { db } from '../../Firebase';
import { monthDayConverter, timeConverter } from '../../helpers/AppHelpers';
import style from './shiftView.module.css';
import TotalTime from './LifeTimeStats/totalStatsComponents/TotalTime';
import TotalDistance from './LifeTimeStats/totalStatsComponents/TotalDistance';
import PayComparison from './LifeTimeStats/totalStatsComponents/PayComparison';
import ActiveTime from './LifeTimeStats/totalStatsComponents/ActiveTime';
import ActiveDistance from './LifeTimeStats/totalStatsComponents/ActiveDistance';
import TripListCard from './TripList';

export default function ShiftView({userId, shiftId}) {
  const [isLoading, setIsLoading] = useState(true);
  const [shift, setShift] = useState({});
  const averages = shift.average;
  const totals = shift.total;
  const tripListURL = '/statistics/history/view-shift/trips';
  const shiftCall = db.collection('users').doc(userId).collection('shifts').doc(`shift-${shiftId}`);

  const getShift = async () => {
    setIsLoading(true);
    const getShift = await shiftCall.get();

    setShift(getShift.data());
    setIsLoading(false);
  }

  useEffect(() => {
  getShift();
  }, [])

  return (
    <>
    {!isLoading &&
      <Route exact path='/statistics/history/view-shift'>
        <div className = {style.tripViewContainer}>
          <div className={style.tripViewHeader}>
            <div className={style.logoContainer}>
              <h1>{shift.total.tripCount}</h1>
              <p>{shift.total.tripCount > 1 ? 'Trips' : 'Trip'}</p>
            </div>

            <div className={style.tripInfo}>
              <h3>{monthDayConverter(shift.start.time)}</h3>
              <p>{timeConverter(shift.start.time)}</p>
              <p>{timeConverter(shift.end.time)}</p>
            </div>

            <div className={style.tripPayInfo}>
              <h3>Pay Rates</h3>
              <p><b>${shift.total.hourlyPay} </b>/hour</p>
              <p><b>${shift.total.perDistancePay} </b>/mile</p>
            </div>
          </div>
        </div>

        <TotalTime info = {totals} title = {`Shift Time`} />
        <TotalDistance info = {totals} title = {`Shift Distance`} />
        <PayComparison info = {totals} title = {`Shift Pay`} />
        <ActiveTime info = {totals} title = {`Active Shift Time`} />
        <ActiveDistance info = {totals} title = {`Active Shift Distance`} />

        <Link to = {tripListURL} className = {style.listCard}>
          <h3>View Completed Trips</h3>
        </Link>

        <PayComparison info = {averages} title = {`Pay Per Trip`} />
        <ActiveTime info = {averages} title = {`Time Per Trip`} />
        <ActiveDistance info = {averages} title = {`Distance Per Trip`} />
      </Route>
    }
      <Route path = {tripListURL}>
        <TripListCard shift = {shiftCall}/>
      </Route>
    </>
  )
}