import React from 'react';
import { useHistory } from 'react-router-dom';
import { FaArrowRight } from 'react-icons/fa';

export default function NewTrip({ updateTrip, trip, setStage, style }) {
  const nextPage = '/shift/active/trip-start';
  const stampInputs = [];
  const history = useHistory();
  const isMultiTrip = 'addOn'

  const newTrip = (e) => {
    e.preventDefault();

    const tripData = {
      stampInputs,
      isMultiTrip
    };

    setStage(nextPage);
    updateTrip(tripData);
    history.push(nextPage);
  };

  return (
    <>
      <form id={style.start} onSubmit = {newTrip} >
        <button type='submit'>Start Trip</button>
        <div className={style.buttonIcon}>
          <FaArrowRight />
        </div>
      </form>
    </>
  );
}