import React from 'react';
import { useLocation } from 'react-router';
import { Route } from 'react-router-dom';
import style from './stats.module.css';
import AveragedStats from './LifeTimeStats/AveragedStats';
import TotalStats from './LifeTimeStats/TotalStats';
import History from './History';
import StatsMenu from './LifeTimeStats/StatsMenu';

export default function Stats({currentUser}) {
  const total = currentUser.lifetime.total;
  const average = currentUser.lifetime.average;
  const profileImage = currentUser.photoURL;
  const userId = currentUser.uid;
  const location = useLocation();

  const headerTitle = location.pathname.replace('/statistics/', '');
  const hideHeader = !location.pathname.includes('view');

  return (
    <div className = {style.component}>
      { hideHeader && 
        <div className={`${style.statsHeader} ${style.card}`}>
          <img src={profileImage} alt="profile-picture"/>
          <div>
            <h2>Lifetime <span>{headerTitle}</span></h2>
            <h4>{total.shiftCount} Shifts</h4>
            <p><b>${total.hourlyPay}</b> / hr</p>
            <h4>{total.tripCount} Trips</h4>
            <p><b>${total.perDistancePay} </b>/ Mile</p>
          </div>
        </div>}

      <Route path = '/statistics/totals'>
        <TotalStats 
            total = {total}
            profileImage = {profileImage}
          />
      </Route>

      <Route path = '/statistics/averages'>
        <AveragedStats 
            average = {average} 
          />
      </Route>

      <Route path = '/statistics/history'>
        <History 
            userId = {userId} 
          />
      </Route>

      <StatsMenu />
    </div>
  )
}