import {currencyAddition, round, hourlyPayCalulator, perDistancePayCalulator} from './AppHelpers';

const distAndTimeCalc = (item, stamps) => {
  const total = {};

  stamps.forEach((stamp) => {
    const currentValue = parseFloat(stamp.stampValue);
    const startValue = parseFloat(item?.start?.[stamp?.title]) || 0;
    const shiftBreak = parseFloat(item?.total?.break) || 0;

    const totalValue = round((currentValue - startValue) - shiftBreak);

    total[stamp.title] = (currentValue ? totalValue : item?.total?.[stamp?.title]);
  })

  return total;
}

const totalPayCalculations = (shift, trip) => {
  const calculationItems = ['providerPay', 'tipPay', 'pay'];
  const totalPayouts = {};

  calculationItems.forEach((item)=> {
    const shiftItem = round(shift?.total?.[item]) || 0;
    const tripItem = round(trip?.total?.[item]) || 0;

    totalPayouts[item] = currencyAddition(shiftItem, tripItem);
  })

  return totalPayouts;
}

const totalStageCalculations = (shift, trip) => {
  const calculationItems = ['pickup', 'wait', 'delivery'];
  const totalStages = {};

  calculationItems.forEach((item) => {
    const itemCategories = ['time', 'distance'];

    const shiftItem = shift?.total?.[item];
    const tripItem = trip?.[item]?.total;

    itemCategories.forEach((category) => {
      const shiftCategory = shiftItem?.[category] || 0;
      const tripCategory = tripItem?.[category] || 0;

      totalStages[item] = {
        ...totalStages[item],
        [category]: currencyAddition(shiftCategory, tripCategory),
      }
    })
  })

  return totalStages;
}

const activeTripMetricCalculations = (shift, trip) => {
  const currentActiveTime = shift?.total?.activeTime || 0;
  const currentActiveDistance = shift?.total?.activeDistance || 0;

  const additionalTripTime = trip?.total?.time;
  const additionalTripDistance = trip?.total?.distance;

  return {
    activeTime: currencyAddition(currentActiveTime, additionalTripTime),
    activeDistance: currencyAddition(currentActiveDistance, additionalTripDistance),
  }
}

const perMetricPayCalculations = (totalPay, runningTotals) => {
  const time = runningTotals.time;
  const distance = runningTotals.distance;

  const perMetricTotals = {
    hourlyPay: round(hourlyPayCalulator(time, totalPay)).toFixed(2),
    perDistancePay: round(perDistancePayCalulator(distance, totalPay)).toFixed(2),
  };

  return perMetricTotals;
}

const averageTotaledCalculations = (totals, totalTrips) => {
  const averagedTotals = {};

  for (const [title, value] of Object.entries(totals)) {
    averagedTotals[title] = round(value / totalTrips);
  }

  return averagedTotals;
}

const averageStageCalculations = (stages, totalTrips) => {
  const averagedStages = {};

  for (const [stage, data] of Object.entries(stages)) {
    for (const [title, value] of Object.entries(data)) {
      averagedStages[stage] = {...averagedStages[stage], [title]: round(value / totalTrips)};
    }
  }

  return averagedStages;
}

export {
  distAndTimeCalc,
  totalPayCalculations,
  perMetricPayCalculations,
  totalStageCalculations,
  activeTripMetricCalculations,
  averageTotaledCalculations,
  averageStageCalculations
}