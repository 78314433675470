import React, { useState, useEffect } from 'react';
import {
  Route,
  Switch
} from 'react-router-dom';
import { useHistory, useLocation } from 'react-router';
import style from './shiftRouter.module.css';
import { animated } from 'react-spring';

import { createShift, createStamps } from '../../helpers/CreationHelpers';
import StartShift from './01-StartShift';
import TripRouter from './trip/01-TripRouter';
import PauseShift from './03-PauseShift';
import EndShift from './04-EndShift';
import ActionMenu from '../../ActionMenu';
import ActiveTripsRouter from './activeTrips/ActiveTripsRouter';
import HomeSummary from '../auth/HomeSummary';

import PayRates from './statComponents/payRates/PayRates';
import MeasurementMetrics from './statComponents/measurementMetrics/MeasurementMetrics';
import TotalShiftPay from './statComponents/totalShiftPay/TotalShiftPay';
import AveragedStages from './statComponents/averagedStages/AveragedStages';
import AveragedTotals from './statComponents/averagedTotals/AveragedTotals';

import sortedState from '../../helpers/trips/UpdateHelpers';
import {
  getSavedState,
  setSavedState,
} from '../../helpers/trips/TripHelpers';
import { useRoutingTransition, useTripAnimation } from '../../helpers/animationHelpers';
import { calculateStats, addTripToShift } from './../../helpers/ShiftHelpers';
import usePersistenetState from '../../customHooks/PersistentState';

export default function ShiftRouter({ 
    currentUser,
    stage,
    setStage,
    addShiftsToUser,
    isShiftUploaded
  }) {

  const history = useHistory();
  const {pathname} = useLocation();
  const [previousId, setPreviousId] = usePersistenetState('routeId', -1);

  const [shift, setShift] = useState(
    getSavedState('shift') || createShift(currentUser?.lifetime?.total?.shiftCount || 0)
  );

  const [stamps, setStamps] = useState(
    getSavedState('shiftStamps') || createStamps()
  );

  const [shiftStageId, setShiftStageId] = useState(getSavedState('shiftStageId'));

  const updateShiftStageId = (stageId) => {
    setSavedState({shiftStageId: stageId})
    setShiftStageId(stageId)
  }

  const updateShift = ({trip, ...data}) => {
    const shiftData = trip ? {
      ...calculateStats({
        trip,
        shift,
        stageId: shiftStageId,
        ...data,  
      }),
    } : {...data, }

    const sortProps = {
      ...shiftData,
      trip: shift,
      trips: [],
      stamps
    }

    const { updatedTrip, sortedStamps} = sortedState(sortProps);
    const finalShift = trip?.completed ? addTripToShift(updatedTrip, trip) : updatedTrip;

    setShift(finalShift);
    setStamps(sortedStamps);

    setSavedState({
      shift: updatedTrip,
      shiftStamps: sortedStamps,
    })
  };

  const deleteShift = () => {
    localStorage.clear();
    
    history.push('/')
    window.location.reload();
  }

  useEffect(() => {
    if (shift.completed) {
      addShiftsToUser(shift);
      localStorage.clear();
      setShift(createShift((currentUser?.lifetime?.total?.shiftCount || 0) + 1));
    }
  }, [shift]);

  const shiftState = {
    shift,
    setShift,
    updateShift,
    shiftStageId,
    updateShiftStageId,
    stage,
    setStage,
    style,
    isShiftUploaded
  }

  const shiftRouteTransition = useRoutingTransition(previousId, setPreviousId);
  const displayShiftContents = {
    ...useTripAnimation(),
    display: pathname === '/' || pathname.includes('/shift/') ? '' : 'none'
  }

  return (
    <animated.div style = {displayShiftContents} className={style.component}>
      <summary>
        { (!pathname.includes('completed-trips') && pathname.includes('shift/'))
          && 
          <>
            <TotalShiftPay
              shift = {shift}
            />
            
            <PayRates
              shift = {shift} 
            />

            <MeasurementMetrics
              shift = {shift}
            />

            <AveragedStages
              shift = {shift}
            />

            <AveragedTotals
              shift = {shift}
            />
          </>
        }

        { pathname === '/' 
          && <HomeSummary currentUser = {currentUser}/>
        }
      </summary>


      {shiftRouteTransition(( styles, item, key) => (
        <animated.section key = {key} style = {styles} >
          <Switch location = {item}>
              <Route exact path = "/">
                <StartShift 
                  {...shiftState}
                  updateShift = {updateShift}
                />
              </Route>

              <Route path = "/shift/active">
                <TripRouter
                  {...shiftState}
                  currentUser = {currentUser}
                  updateShift = {updateShift}
                  previousId = {previousId}
                  setPreviousId = {setPreviousId}
                />
              </Route>

              <Route path = "/shift/break">
                <PauseShift {...shiftState} />
              </Route>

              <Route path = "/shift/clock-out">
                <EndShift {...shiftState} />
              </Route>

              <Route path = "/shift/completed-trips">
                <ActiveTripsRouter {...shiftState} />
              </Route>
          </Switch>
      </animated.section>
      ))}

      { pathname.includes('shift/') && !pathname.includes('trip') && <ActionMenu shiftActions = {deleteShift}/>}
    </animated.div>
  )
}