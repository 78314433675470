import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import style from './forms.module.css';

import EmailAndPassword from './formComponents/EmailAndPassword';

export default function Login({ login}) {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState('');
  const history = useHistory();

  const emailAndPasswordState = {
    email,
    setEmail,
    password,
    setPassword,
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true);
      await login(email, password);
      history.push('/');
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className = {style.accountForm}>
      <form onSubmit = {handleSubmit}>
        <h3>Login to DriveGig</h3>

        <EmailAndPassword {...emailAndPasswordState} />

        <button
          type = "submit"
          disabled = {isLoading}
        >
          Login
        </button> 

        <div>
        <Link to = "/account/forgot-password">Forgot Password?</Link>
      </div>

      <div>
        Need to create an Account?
        {' '}
        <Link to = "/account/signup">Sign Up</Link>
      </div>
      </form>
    </div>
  );
}