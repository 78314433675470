import React, { useState, useEffect } from 'react';
import {
  Route,
  Redirect,
  Link,
} from 'react-router-dom';
import PrivateRoute from './components/routes/PrivateRoute';
import { auth, db, storage } from './Firebase';

import Nav from './Nav';
import './App.css';
import Gasform from './components/gas/GasForm';
import Stats from './components/stats/Stats';
import AuthRouter from './components/auth/AuthRouter';
import ShiftRouter from './components/shift/00-shiftRouter';
import { userStatsCalculations } from './helpers/UserStatsHelpers';

export default function App() {
  const [currentUser, setCurrentUser] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [stage, setStage] = useState(localStorage.getItem('stage') || '');

  const retrieveUser = () => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      setIsLoading(true);
      if (user) {
        const userDetails = (await db.collection('users').doc(user.uid).get()).data();
        let userProviders = [];

        await (async () => {
          const clientList = userDetails.clientList;
          const providerList = await db.collection('providers').get();

          providerList.forEach((doc) => {
            const document = doc.data();
            clientList.forEach((client) => {
              return document.displayName === client ? userProviders.push({...document}) : '';
            })
          });
        })();
        setCurrentUser({ ...user, ...userDetails, clientList: userProviders});
      }
      setIsLoading(false)
    });
    return unsubscribe;
  }

  useEffect(() => {
    retrieveUser()
  }, []);

  useEffect(() => {
    localStorage.setItem('stage', stage);
  }, [stage]);

const addShiftsToUser = (shiftData) => {
    const {trips, ...completedShift } = shiftData;
    const { total } = completedShift;
    const lifetime = userStatsCalculations( total, currentUser);

    const user = db.collection('users').doc(currentUser.uid);

    const saveShift = async () => {
      user.update({
        lifetime
      })

      const shift = user.collection('shifts').doc(`shift-${completedShift.id}`);

      await shift.set({...completedShift}, {merge: true});

      trips.forEach((tripData) => {
        const {time, miles, ...extractedTrip} = tripData;

        const trip = shift.collection('trips').doc(`trip-${extractedTrip.id}`);
        trip.set({...extractedTrip})
      })
    } 

    return !isLoading && saveShift() && retrieveUser();
  }

  const authState = {
    auth,
    db,
    storage
  };

  const appState = {
    currentUser,
    setStage,
    stage,
    addShiftsToUser
  };

  return (
    <div className = "container">
      <header>
        <h1 className='logo'><Link to = "/">DriveGig</Link></h1>
      </header>

      <main>
          {
            !isLoading
            && (
              <>
                <PrivateRoute
                    path = "/"
                    component = {ShiftRouter}
                    userState = {appState}
                />
                  
                <Route path = "/account">
                  <AuthRouter {...appState} {...authState} />
                </Route>

                <Route path = "/resume-shift">
                  {stage ? <Redirect to = {stage} /> : <Redirect to = "/shift/clock-in" />}
                </Route>

                <Route path = "/input-expenses">
                  <Gasform />
                </Route>

                <Route path = "/statistics">
                  <Stats 
                    currentUser = {currentUser}
                  />
                </Route>
              </>
            )
          }
      </main>
      <Nav stage = {stage}/>
    </div>
  );
}