import React, {useState, useEffect} from 'react';
import { db } from '../../Firebase';
import style from './../shift/activeTrips/tripList.module.css';
import { monthDayConverter, timeConverter } from '../../helpers/AppHelpers';
import { Route, useHistory } from 'react-router-dom';
import usePersistenetState from '../../customHooks/PersistentState';
import ShiftView from './ShiftView';
import { getSavedState } from '../../helpers/trips/TripHelpers';

export default function History({userId}) {
  const [isLoading, setIsLoading] = useState(true);
  const [shifts, setShifts] = useState([]);
  const [shiftId, setShiftId] = usePersistenetState('historyTripId', getSavedState('historyTripId'));
  const history = useHistory();

  const handleClick = (id) => {
    setShiftId(id);
    history.push('/statistics/history/view-shift');
  }
  
  const getShifts = async () => {
    setIsLoading(true);
    const getShifts = (await db.collection('users').doc(userId).collection('shifts').limit(10).get());
    const shiftsList = []

    getShifts.forEach((shift) => {
      shiftsList.push(shift.data());
    });

    setShifts(shiftsList);
    setIsLoading(false);
  }

  useEffect(() => {
    getShifts();
  }, []);

  return (
    <>
      {!isLoading &&
        <Route exact path = '/statistics/history'>
          <div 
            style = {{
              gridColumn: '1 / -1',
              width: '100%',
              gridTemplateColumns: 'repeat(auto-fit, 100%)'
            }} 
            className = {style.tripListContainer}>
              
            {shifts.map((shift) => (
              <div
                onClick = {() => handleClick(shift.id)}
                key = {shift.id}
                style = {{width: '100%'}}
                className = {style.tripItem}>

                <div className={style.logo}>
                  <h1>{shift.total.tripCount}</h1>
                  <p>{shift.total.tripCount > 1 ? 'Trips' : 'Trip'}</p>
                </div>

                <div className={style.dateAndTime}>
                  <h3>{monthDayConverter(shift.start.time)}</h3>
                  <p>
                    <span>{timeConverter(shift.start.time)}</span>
                    <span>{timeConverter(shift.end.time)}</span>
                  </p>
                </div>

                <div className = {style.payInfo}>
                <h3>Pay Rates</h3>
                <p><b>${shift.total.hourlyPay}</b> /hour</p>
                <p><b>${shift.total.perDistancePay}</b> /mile</p>
                </div>
              </div>
              ))
            }
          </div>
        </Route>
      }

      <Route path = '/statistics/history/view-shift'>
        <ShiftView 
          userId = {userId} 
          shiftId = {shiftId}
        />
      </Route>
    </>
  )
}