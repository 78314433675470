import React from "react";
import { FaSearchDollar, FaList, FaTrash, FaRegEdit } from 'react-icons/fa';
import { AiFillEdit } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';

function ShiftActions({shiftActions}) {
  const history = useHistory('');
  return (
    <>
      {/* <li className = 'actionCategory'>
        <span>Add Expense</span>
        <span className = 'menuAction'><FaSearchDollar/></span>
      </li> */}

      <li onClick = {() => {history.push('/shift/completed-trips')}}  className = 'menuAction'>
        <span className = 'actionLabel'>View Trips</span>
        <span className = 'actionIcon'><FaList/></span>
      </li>

      <li onClick = {shiftActions} className = 'menuAction'>
        <span className = 'actionLabel'>Delete Shift</span>
        <span className = 'actionIcon'><FaTrash/></span>
      </li>
    </>
  )
}

function TripActions({tripActions}) {
  return (
    <>
      {/* <li className = 'menuAction'>
        <span className = 'actionLabel'>Edit Trip</span>
        <span onClick = {tripActions} className = 'actionIcon'><AiFillEdit/></span>
      </li> */}

      <li className = 'menuAction'>
        <span className = 'actionLabel'>Delete Trip</span>
        <span onClick = {tripActions} className = 'actionIcon'><FaTrash/></span>
      </li>
    </>
  )
}

function GlobalActions() {
  return (
    <>
      <li className = 'menuAction'><FaSearchDollar/></li>
    </>
  )
}

export {ShiftActions, TripActions, GlobalActions}