import React, { useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import style from './forms.module.css';
import EmailAndPassword from './formComponents/EmailAndPassword';
import usePersistenetState from '../../customHooks/PersistentState';

export default function Signup({ signUp, db }) {
  const [firstName, setfirstName] = usePersistenetState('firstName','');
  const [lastName, setlastName] = usePersistenetState('lastName','');
  const [email, setEmail] = usePersistenetState('email', '');
  const [password, setPassword] = usePersistenetState('password','');
  const [passwordConf, setPasswordConf] = usePersistenetState('passwordConf','');
  const [clients, setClients] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [photo, setPhoto] = useState();
  const [providers, setProviders] = useState([]);
  const history = useHistory();

  const getProviders = async () => {
    const providersRef = await db.collection('providers').get();
    const list = providersRef.docs.map(doc => doc.data());
    setProviders(list);
  }

  useEffect(() => {
    getProviders();

    return () => {
      setIsLoading(false);
      localStorage.clear();
    }
  }, []);

  const emailAndPasswordState = {
    email,
    setEmail,
    password,
    setPassword,
  };

  const handleCheckbox = (e) => {
    if (e.target.checked) {
      setClients([...clients, e.target.value]);
    } else {
      setClients(clients.filter(client => client !== e.target.value));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== passwordConf) {
      setError('Passwords do not match');
    }

    const signUpInfo = {
      firstName,
      lastName,
      email,
      password,
      clients,
      photo
    };

    try {
      setIsLoading(true);
      setError('');
      await signUp(signUpInfo);

      history.push('/');
    } catch(err) {
      setIsLoading(false);
      setError(err.message);
    }
  };

  return (
    <div className = {style.accountForm}>
      <form onSubmit = {handleSubmit}>
        <h3>Create Account</h3>

        <label htmlFor = "firstName">
          <input
            required
            type = "text"
            name = "firstName"
            placeholder = "First Name"
            onChange = {e => setfirstName(e.target.value)}
            value = {firstName}
          />
        </label>

        <label htmlFor = "lastName">
          <input
            required
            type = "text"
            name = "lastName"
            placeholder = "Last Name"
            onChange = {e => setlastName(e.target.value)}
            value = {lastName}
          />
        </label>

        <EmailAndPassword {...emailAndPasswordState} />

        <label htmlFor = "passwordConf">
          {}
          <input
            required
            type = "password"
            name = "passwordConf"
            placeholder = "Confirm Password"
            onChange = {e => setPasswordConf(e.target.value)}
            value = {passwordConf}
          />
        </label>

        <label htmlFor="profilePhoto">
          <h4>Add Profile Photo</h4>
          <input
            required
            onChange = {e => setPhoto(e.target.files[0])} 
            type="file" 
            accept='image/*' 
            id='profilePhoto' 
          />
        </label>

        <div className={style.providers}>
          <h4>Select the services you use</h4>
          {providers.map((provider) => (
            <div key = {provider.displayName}>
              <input
                type="checkbox"
                name='DrivingClient'
                value={provider.displayName}
                id={provider.displayName.replace(' ','').toLowerCase()}
                onChange = {handleCheckbox}
              />
              <label 
                htmlFor={provider.displayName.replace(' ','').toLowerCase()}
              >
                <img src={provider.logoURL} alt="" />
              </label>
            </div>
          ))}
        </div>

        {error && <div className={style.errorBox}>{error}</div>}

        <button
          type = "submit"
          disabled = {
            !firstName ||
            !lastName ||
            !email ||
            !password ||
            !passwordConf ||
            !clients.length > 0 ||
            !photo ||
            isLoading
          }>
            Sign Up
        </button>

        <div>
          Already have an account?
          {' '}
          <Link to = "/account/login">Log In</Link>
        </div>
      </form>
    </div>
  );
}