import { createStamp } from './trips/TripHelpers';
import {
  distAndTimeCalc, 
  totalPayCalculations, 
  perMetricPayCalculations, 
  totalStageCalculations ,
  activeTripMetricCalculations,
  averageTotaledCalculations,
  averageStageCalculations
} from './StatsHelpers';

function addTripToShift(shift, trip) {
  const shiftCopy = shift;
  const shiftTrips = shiftCopy.trips;
  const filteredTrips = shiftTrips.filter(storedTrip => storedTrip.id !== trip.id)

  shiftCopy.trips = [...filteredTrips, trip];

  return shiftCopy;
}

function stampGenerator(trip, stage) {
  const placement = 'end';
  const time = trip?.end ? trip?.end?.time : trip?.start?.time;
  const distance = trip?.end ? trip?.end?.distance : trip?.start?.distance;

  const timeStamp = createStamp({
    title:'time', 
    stampValue: time, 
    stage,
    placement
  });

  const odomStamp = createStamp({
    title:'distance', 
    stampValue: distance,
    stage,
    placement
  });

  const stampInputs = [
    {...timeStamp, placement, stage},
    {...odomStamp, placement, stage}
  ];

  return stampInputs;
}

function calculateStats({shift, trip, stageId, ...remainingData}) {
  const tripStart = !trip?.diner;
  const tripCompleted = !!trip?.completed;
  const stampsTrigger = (tripStart || tripCompleted);
  const complete = trip.completed

  const stampInputGeneration = () => {
    if (tripCompleted) {
      const tripCompletedStage = !stageId?.length >= 0  ? `Trip-${stageId}` : `Trips-${stageId}`;
      return stampGenerator(trip, tripCompletedStage)
    } else {
      const tripStartStage = trip?.id > 0 ? `nonActive-${stageId}` : 'startShift';
      return stampGenerator(trip, tripStartStage);
    }
  }
  
  const generatedStamps = stampInputGeneration();

  const totalTrips = trip.completed ? (shift?.total?.tripCount || 0) + 1 : shift?.total?.tripCount;

  const runningTotals = distAndTimeCalc(shift, generatedStamps);
  const payOutTotals = totalPayCalculations(shift, trip);
  const stageTotals = complete ? totalStageCalculations(shift, trip) : {};
  const perMetricPayoutTotals = perMetricPayCalculations(payOutTotals.pay, runningTotals);
  const activeMetrics = complete ? activeTripMetricCalculations(shift, trip) : {};

  const totalsToAverage = {
    ...runningTotals,
    ...payOutTotals,
    ...activeMetrics,
  }

  const total = {
    ...shift.total,
    ...totalsToAverage,
    ...perMetricPayoutTotals,
    ...stageTotals,
    tripCount: totalTrips
  };

  const averagedTotals = complete ? averageTotaledCalculations(totalsToAverage, totalTrips) : {};
  const averagedStages = complete ? averageStageCalculations(stageTotals, totalTrips) : {}

  const average = {
    ...shift.average,
    ...averagedTotals,
    ...averagedStages
  }
  
  const stampInputs = stampsTrigger ? generatedStamps : [];

  const shiftData = {
    ...remainingData,
    stampInputs,
    total,
    average
  }

  return shiftData;
}

export { addTripToShift, calculateStats};