import React from 'react';
import { Link } from 'react-router-dom';
import style from './homePage.module.css';

export default function HomeSummary({ currentUser, stage }) {
  return (
    <div className = {style.homePage}>
      <img src={currentUser.photoURL} alt="" />
      <p>{currentUser.firstName} {currentUser.lastName.split('').shift()}.</p>

      <div className = {style.providers}>
        <h2>Partnered Apps</h2>
          {currentUser.clientList.map((client, index) => {
            return <img key={index} src={client.logoURL} alt=""/>
          })}
      </div>
    </div>
  );
}