import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaChartLine, FaUserAlt, FaCar} from 'react-icons/fa';
import { useLocation } from 'react-router';

export default function Nav({stage}) {
  const path = useLocation().pathname;

  const activeHome = path.includes('/shift/') || path === '/' ? 'active' : ''
  const activeInsights = path.includes('/statistics/') ? 'active' : '';
  const activeProfile = path.includes('/account/') ? 'active' : '';

  return (
    <nav>
      <ul>
        <Link to = '/account/profile'>
          <li>
            <span className = {activeProfile}>
              <FaUserAlt size = {activeProfile ? "3.9vh" : "1.8vh"} />
            </span>

            <span className = {`label`}>
              Profile
            </span>

          </li>
        </Link>

        <Link to = {stage ? stage : '/'}>
          <li className = {activeHome}>
            <span className = "icon">
              <FaCar size = {activeHome ? "3.9vh" : "1.8vh"} />
            </span>

            <span className = {`label`}>
              Drive
            </span>

          </li>
        </Link>

        <Link to = "/statistics/totals">
          <li>
            <span className = {activeInsights}>
              <FaChartLine size = {activeInsights ? "3.9vh" : "1.8vh"} />
            </span>

            <span className = {`label`}>
              Insights
            </span>
          </li>
        </Link>
      </ul>
    </nav>
  );
}