import React from 'react'
import { useHistory } from 'react-router';
import { setSavedState } from '../../../helpers/trips/TripHelpers';
import style from './tripList.module.css';
import { monthDayConverter, timeConverter } from '../../../helpers/AppHelpers';

export default function ActiveTripsList({setViewableTripId, shift}) {
  const history = useHistory();

  const handleClick = (tripId) => {
    setSavedState({viewableTripId: tripId});
    setViewableTripId(tripId);
    history.push('/shift/completed-trips/view-trip');
  }

  return (
    <div className = {style.tripListContainer}>
      {shift.trips.map(trip => (
        <div onClick = {() => handleClick(trip.id)} key = {trip.id} className = {style.tripItem}>
          <div className={style.logo}>
            <p>{trip.orderProvider.split(/(?=[A-Z])/).join(' ')}</p>
          </div>

          <div className={style.dateAndTime}>
            <h3>{monthDayConverter(trip.start.time)}</h3>

            <p>
              <span>{trip.restaurant}</span>
              <span>{trip.type}</span>
            </p>
          </div>

          <div className = {style.payInfo}>
            <h3>Pay Rates</h3>
            <p><b>${trip.total.runRate}</b> /hour</p>
            <p><b>${trip.total.perDistancePay}</b> /mile</p>
          </div>
        </div>
      ))}
    </div>
  )
}