import React from 'react';
import style from './totalShiftPay.module.css';

export default function TotalShiftPay({shift}) {
  return (
    <div className={style.statContainer}>
      <h4 className = {`${style.statsCard} ${style.statsHeader}`}>
        Pay
      </h4>

      <div className = {style.cardStat}>
        <p className = {style.label}>Fare</p>
        <p className={style.stat}>${shift?.total?.providerPay}</p>
      </div>

      <div className = {style.cardStat}>
        <p className = {style.label}>Tips</p>
        <p className={style.stat}>${shift?.total?.tipPay}</p>
      </div>

      <div className = {`${style.cardStat} ${style.fullSpanStat}`}>
        <p className = {style.label}>Total</p>
        <p className={style.stat}>${shift?.total?.pay}</p>
      </div>
    </div>
  )
}