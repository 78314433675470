const createShift = (totalShifts) => {
  const id = totalShifts
  const trips = [];
  const completed = false;
  const stageInfo = [];

  return {
    id,
    trips,
    completed,
    stageInfo
  };
};

const createTrip = (totalTrips) => {
  const id = totalTrips;
  const diner = '';
  const restaurant = '';
  const orderProvider = '';
  const completed = false;
  const type = 'standard';

  return { id, orderProvider, diner, restaurant, type, completed };
};

const createStamps = () => {
  const stage = 'pickup';

  const start = {
  }

  const end = {
  }

  const total = {
  }

  return {stage, start, end, total };
};

export { createShift, createTrip, createStamps };