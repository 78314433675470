import { useState, useEffect } from 'react';

function getSavedValue(key, initialValue) {
  const localItem = localStorage?.getItem(key);
  const localValue = JSON.parse(localItem);

  if (localValue) return localValue;

  if (initialValue instanceof Function) return initialValue()
  return initialValue
}

export default function usePersistenetState(key, initialValue) {
  const [value, setValue] = useState(() => {
    return getSavedValue(key, initialValue);
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value))
  }, [value])

  return [value, setValue];
}