import React from 'react';
import style from './../stats.module.css';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';


export default function StatsMenu() {
  const path = useLocation().pathname;

  const activeTotals = path.includes('/statistics/totals') ? `${style.activeItem}` : ''
  const activeAverages = path.includes('/statistics/averages') ? `${style.activeItem}` : '';
  const activeHistory = path.includes('/statistics/history') ? `${style.activeItem}` : '';

  return (
    <div className = {style.statsMenu}>
      <Link 
        to='/statistics/totals'
        className={`${style.menuItem} ${activeTotals}`}
      >
        Totals
      </Link>

      <Link 
        to='/statistics/averages'
        className={`${style.menuItem} ${activeAverages}`}
      >
        Averages
      </Link>

      <Link 
        to='/statistics/history'
        className={`${style.menuItem} ${activeHistory}`}
      >
        History
      </Link>
    </div>
  )
}