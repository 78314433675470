import React, {useState, useEffect} from 'react';
import { Route, useHistory } from 'react-router';
import usePersistenetState from '../../customHooks/PersistentState';
import { monthDayConverter, timeConverter } from '../../helpers/AppHelpers';
import { getSavedState } from '../../helpers/trips/TripHelpers';
import style from './statsListView.module.css';
import TripView from './TripView';

export default function TripListCard({shift}) {
  const [isLoading, setIsLoading] = useState(true);
  const [trips, setTrips] = useState({});
  const [tripId, setTripId] = usePersistenetState('tripViewId', getSavedState('tripViewId'));
  const tripsPath = shift.collection('trips');
  const history = useHistory();

  const handleClick = (id) => {
    setTripId(id);
    history.push('/statistics/history/view-shift/trips/view-trip');
  };
  
  const getTrips = async () => {
    setIsLoading(true);
    const tripList = [];

    const getTrips = await tripsPath.get();

    getTrips.forEach((trip) => {
      tripList.push(trip.data());
    })

    setTrips(tripList);
    setIsLoading(false);
  }

  useEffect(() => {
    getTrips();
  },[])

  return (
    <>
    <Route exact path = '/statistics/history/view-shift/trips'>
      <div className = {style.tripListContainer}>
          {!isLoading &&
            trips.map((trip) => (
              <div onClick = {() => handleClick(trip.id)} className = {style.tripItem} key = {trip.id}>
                <div className={style.logo}>
                  <p>{trip.orderProvider.split(/(?=[A-Z])/).join(' ')}</p>
                </div>

                <div className={style.dateAndInfo}>
                  <h3>{monthDayConverter(trip.start.time)}</h3>
                  <p>
                    <span>{trip.restaurant}</span>
                    <span>{trip.type}</span>
                  </p>
                </div>

                <div className = {style.payInfo}>
                  <h3>Pay Rates</h3>
                  <p><b>${trip.total.runRate}</b> /hour</p>
                  <p><b>${trip.total.perDistancePay}</b> /mile</p>
                </div>
              </div>
            ))
          }
        </div>
    </Route>

    <Route path = '/statistics/history/view-shift/trips/view-trip'>
      <TripView tripsPath = {tripsPath}  tripId = {tripId}/>
    </Route>
    </>
  )
}