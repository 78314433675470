import React, {useState, useEffect} from 'react';
import style from './misc.module.css';

export default function DistanceInputError({canProceed, previousStamp}) {
  const [error, setError] = useState('');

  useEffect(() => {
    if (!canProceed) {
      setError(`Your input can't be less than ${previousStamp}`);
    } else {
      setError('');
    }
  }, [canProceed])

  return (
    <>
      { error && <div className = {style.errorBox}>{error}</div> }
    </>
  )
}