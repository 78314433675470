import React from 'react';
import { useHistory } from 'react-router-dom';
import { createStamp } from '../../../helpers/trips/TripHelpers';
import useDistanceInput from '../../../customHooks/DistanceInput';
import DistanceInputError from '../../micsComponents/DistanceInputError';
import { canProceedCalc } from '../../../helpers/AppHelpers';

export default function StartTrip({ 
  trip, 
  updateTrip,
  style,
  setStage,
  runningDistanceTotal
}) {
  const [odometer, setOdometer] = useDistanceInput(trip?.start?.distance || '');
  const nextPage = '/shift/active/trip-pickup';
  const history = useHistory();
  const previousStamp = runningDistanceTotal()
  const canProceed = canProceedCalc(previousStamp, odometer);

  const startTrip = (e) => {
    e.preventDefault();

    if (!canProceed) {
      return;
    }

    const stampDate = Date.now();
    const date = new Date(stampDate).setUTCHours(0,0,0,0);
  
    const timeStamp = createStamp({
      title:'time', 
      stampValue: stampDate, 
      stage: 'pickup', 
      placement: 'start'
    });
  
    const odomStamp = createStamp({
      title:'distance', 
      stampValue: odometer, 
      stage: 'pickup', 
      placement: 'start'
    });
    
    const start = {
      time: stampDate,
      distance: odometer
    }

    const stampInputs = [timeStamp, odomStamp];

    const tripData = {
      stampInputs,
      date,
      start
    };

    setStage(nextPage);
    updateTrip(tripData);
    history.push(nextPage);
  };

  const errorData = {
    previousStamp,
    canProceed,
  }

  return (
    <div className = {style.stepCard}>
      <h4>Order Received</h4>

      <form onSubmit = {startTrip}>
        <label htmlFor = "odometer">

          {odometer.length > 2 && <DistanceInputError {...errorData} />}

          <input
            className = {!canProceed ? style.inputError : ''}
            type = "number"
            name = "odometer"
            placeholder = "Current Odometer"
            onChange = {e => setOdometer(e.target.value)}
            value = {odometer}
            step = 'any'
            required
          />
        </label>

        <button 
          type = "submit"
          disabled = {!odometer || !canProceed}
        >
          Head for Pickup
        </button>
      </form>
    </div>
  );
}