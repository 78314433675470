import React from 'react';
import style from './measurementMetrics.module.css';
import { millisecondsToTimeConversion } from '../../../../helpers/AppHelpers';

export default function MeasurementMetrics({shift}) {
  const startTime = new 
    Date(shift?.start?.time)
    .toLocaleString(
      'en-US', {
        hour: 'numeric',
        minute: 'numeric'
    })

  const shiftTimer = millisecondsToTimeConversion(shift?.total?.time)

  const shiftTimerArr = shiftTimer.split(' ');
  const startTimeArr = startTime.split(' ');

  return (
    <div id={style.measurementMetrics} className={style.statContainer}>
      <h4 className = {`${style.statsHeader}`}>Measurement Metrics</h4>

      <div className={`${style.cardStat}`}>
        <p className = {style.stat}>
          <span>{startTimeArr[0]}</span>
          <span>{startTimeArr[1]}</span>
        </p>
        <p className = {style.label}>Start Time</p>
      </div>

      <div className={style.cardStat}>
        <p id ={style.tripCount} className = {style.stat}>{shift?.total?.tripCount || '-'}</p>
        <p className = {style.label}>Trips</p>
      </div>

      <div className={style.cardStat}>
        <p className = {style.stat}>
          <span>{shiftTimerArr[0]}</span>
            <br />
          <span>{shiftTimerArr[1]}</span>
        </p>
        <p className = {style.label}>Driving</p>
      </div>

      <div className={style.cardStat}>
        <p className = {style.stat}>{shift?.total?.distance || '-'}</p>
        <p>Miles <br /> Driven</p>
      </div>
    </div>
  )
}