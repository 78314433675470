import React, {useState} from 'react';
import { useSpring, animated } from 'react-spring';
import useMeasure from './customHooks/useMeasure';
import { FaBars } from 'react-icons/fa';
import { useLocation } from 'react-router';
import { ShiftActions, TripActions } from './components/ActionItems';

export default function ActionMenu({tripActions, shiftActions}) {
  const vH = window.innerHeight;
  const {pathname} = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [bind, {height, width}] = useMeasure();
    const animation = useSpring({
      height: isOpen ? height + (vH * .024) : vH * .07,
      width: isOpen ? width  + (vH * .024) : vH * .07,
      borderRadius: isOpen ? "5%" : '50%',
      boxShadow: '0 0 5px 0 rgba(0,0,0, .25)',
      padding: isOpen ? '2%' : '0%',
    })

  return (
    <animated.div style={animation} className = 'actionMenuWrapper'>
      <ul {...bind}  className = 'actionMenu'>

        {pathname.includes('shift') 
          && (pathname.includes('trip') 
          ? <TripActions tripActions = {tripActions}/> 
          : <ShiftActions shiftActions = {shiftActions}/>
        )}

        {pathname.includes('shift') 
          && <li 
              onClick = {() => {setIsOpen(!isOpen)}} 
              className ='menuIcon'>
                <FaBars/>
            </li>
        }
      </ul>
    </animated.div>
  )
}