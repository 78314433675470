import React from 'react';
import style from './averagedStages.module.css';
import { millisecondsToTimeConversion } from '../../../../helpers/AppHelpers';

export default function AveragedStages({shift}) {
  return (
    <div className={style.statContainer}>
      <div className={style.stageChild}>
        <h4 className={style.statsHeader}>
          Avg. Pickup
        </h4>
        <div className={style.cardStat}>
          <p className={style.label}>Time</p> 
          <p className={style.stat}>{millisecondsToTimeConversion(shift?.average?.pickup?.time)}</p>
        </div>
        <div className={style.cardStat}>
          <p className={style.label}>Distance</p>
          <p className={style.stat}>{shift?.average?.pickup?.distance || '-'}</p>
        </div>  
      </div>

      <div id = {style.wait} className={style.stageChild}>
        <h4 className={style.statsHeader}>
          Avg. Wait
        </h4>
        <div className={style.cardStat}>
          <p className={style.label}>Time</p>
          <p className={style.stat}>{millisecondsToTimeConversion(shift?.average?.wait?.time)}</p>
        </div>
      </div>

      <div className={style.stageChild}>
        <h4 className={style.statsHeader}>
          Avg. Delivery
        </h4>
        <div className={style.cardStat}>
          <p className={style.label}>Time</p>
          <p className={style.stat}>{millisecondsToTimeConversion(shift?.average?.delivery?.time)}</p>
        </div>
        <div className={style.cardStat}>
          <p className={style.label}>Distance</p>
          <p className={style.stat}>{shift?.average?.delivery?.distance || '-'}</p>
        </div>
      </div>
    </div>
  )
}